import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import TextInput from "../../application-form/text-input/text-input.js";
import CreateQuestion from "./create-question/create-question.js";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import QuestionElement from "./question-element/question-element.js";

class FormSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newQuestion: ""
    };
    this.total = 0;
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  render() {
    const locked = this.props.lockedQs;
    const random = this.props.randomQs;
    const lockedDivs = [];
    const randomDivs = [];

    if (locked || random) {
      for (var i in locked) {
        lockedDivs.push(<QuestionElement key={i} question={locked[i]} />);
      }
      for (var x in random) {
        randomDivs.push(<QuestionElement key={x} question={random[x]} />);
      }
    }

    this.total = lockedDivs.length + Number(this.props.numRand);

    return (
      <div className={style.form_settings}>
        <div className={classNames(style.container_s, style.container)}>
          {/* Locked Questions*/}
          <h1 className={style.header}>Locked</h1>
          <p className={style.subtext}>
            These questions will be on every form in the order listed below.
          </p>
          {lockedDivs}

          {/* Number of Random Questions*/}
          <h1 className={style.header}>Number of Random Questions</h1>
          <p className={style.subtext}>
            How many random questions should be chosen?
          </p>
          <div className={style.selector}>
            <p className={style.before_input}>{lockedDivs.length} + </p>
            {
              <TextInput
                inputs={[
                  {
                    key: "numRand",
                    placeholder: "Number of Random Questions",
                    type: "text",
                    defaultValue: this.props.numRand
                  }
                ]}
                hasDefaultValue={true}
                onChange={this.handleChange}
              />
            }
            <div className={style.total}>
              <p className={style.label}>Total:</p>
              {/* add dynamic total amount */}
              <p className={style.amount}>{this.total}</p>
            </div>
          </div>

          {/* Random Question Pool*/}
          <h1 className={style.header}>Random Question Pool</h1>
          <p className={style.subtext}>
            {this.props.numRand ? this.props.numRand : 0} of the following{" "}
            {randomDivs.length} questions will be selected at random to show on
            the form.
          </p>
          <CreateQuestion />
          {randomDivs}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const questions = state.firestore.ordered.questions;
  var locked = null;
  var random = null;
  if (questions) {
    locked = Object.values(questions).filter(function(qItem) {
      return qItem.status === "locked";
    });
    locked.sort(function(a, b) {
      return a.order - b.order;
    });
    random = Object.values(questions).filter(function(qItem) {
      return qItem.status === "random";
    });
    random.sort(function(a, b) {
      return new Date(b.creation_date) - new Date(a.creation_date);
    });
  }
  const configs = state.firestore.ordered.configuration;
  var numRand = 0;
  if (configs) {
    var formSettings = configs.find(x => x.id === "application-form");
    numRand = formSettings.numRand;
  }

  return {
    lockedQs: locked,
    randomQs: random,
    numRand: numRand
  };
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: "questions" },
    { collection: "configuration" }
  ])
)(FormSettings);
