import React, { Fragment } from "react";
import ImageSlider from "./image-slider/image-slider.js";
import Footer from "../layout/footer/footer.js";
import QandA from "./q-and-a/q-and-a.js";
import ArtistIntro from "./artist-intro/artist-intro.js";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import style from "./style.module.scss";
import classNames from "classnames";
import "../../styles/global.css";
import PostList from "../blog-display/post-list/post-list.js";

class ArtistDetails extends React.Component {
  render() {
    const { artist } = this.props;
    if (artist) {
      //Choose random artist
      var arr = [];
      var randPicks = [];
      var artID = this.props.artistID;
      var artists = Object.values(this.props.artists).filter(function(temp) {
        return temp.id !== artID;
      });
      artists = artists.filter(function(artist) {
        return artist.status === "published";
      });

      while (arr.length < artists.length && arr.length < 4) {
        var randomnumber = Math.floor(Math.random() * artists.length) + 1;
        if (arr.indexOf(randomnumber) > -1) continue;
        arr[arr.length] = randomnumber;
        randPicks[randPicks.length] = artists[randomnumber - 1];
      }

      const questions = artist.questions;
      const questionsGroup = [];

      for (var index in questions) {
        const curQ = questions[index];
        const display = curQ.answer && curQ.display ? true : false;
        questionsGroup.push(
          <QandA
            info={curQ}
            key={curQ.id}
            editable={false}
            display={display}
            secret={false}
          />
        );
      }

      return (
        <Fragment>
          <ImageSlider artist={this.props.artist} artistID={artID} />
          <div className={classNames(style.container_s)}>
            <ArtistIntro artist={this.props.artist} secret={false} />
            {questionsGroup}
          </div>
          <div className={classNames(style.banners)}>
            <PostList artists={randPicks} detail={"another"} />
          </div>
          <Footer />
        </Fragment>
      );
    } else {
      return (
        <div>
          <p>Loading...</p>
        </div>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  const id = ownProps.match.params.id;
  let artists = state.firestore.data.artists;
  const artist = artists ? artists[id] : null;
  return {
    artistID: id,
    artists: state.firestore.ordered.artists,
    artist: artist
  };
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "artists" }])
)(ArtistDetails);
