import Firebase from "firebase/app";
import sortBy from "lodash/sortBy";

export const getImageUrl = (imageName, folder) => {
  return Firebase.storage()
    .ref(`${folder}/${imageName}`)
    .getDownloadURL();
};

export const updateAllImages = (allImages, collectionName, docID) => {
  // console.log(allImages);
  Firebase.firestore()
    .collection(collectionName)
    .doc(docID)
    .update({
      imageLoader: {
        images: [...allImages]
      }
    });
};

export const setAllUrls = (allImages, collectionName, docID) => {
  var newImagesArray = [];
  allImages.forEach(image => {
    getImageUrl(image.fileName).then(url => {
      newImagesArray.push({
        fileName: image.fileName,
        fileUrl: url,
        order: image.order
      });
      if (newImagesArray.length === allImages.length) {
        newImagesArray = sortBy(newImagesArray, ["order"]);
        updateAllImages(newImagesArray, collectionName, docID);
      }
    });
  });
};

// export const example = allImages => {
//   Promise.all(allImages.map(image => getImageUrl(image.fileName))).then(
//     urls => {
//       this.setState({ loadedUrls: urls });
//     }
//   );
// };
