import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";

class Backdrop extends React.Component {
  render() {
    return (
      <div
        className={classNames(style.backdrop, {
          [style.hide]: !this.props.menuDrawerOpen
        })}
        onClick={this.props.handleMenuDrawerClose}
      />
    );
  }
}

export default Backdrop;
