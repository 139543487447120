import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";
import uuid from "uuid/v4";

import {
  addImage,
  removeImage,
  updateApplication
} from "../../../../../store/actions/application-actions.js";
import {
  updateAllImages,
  getImageUrl
} from "../../../../../store/api/image-uploader-api";

import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Trash2, Menu, Eye, EyeOff } from "react-feather";
import Sortable from "react-sortablejs";

class ImageMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFoldable: props.isFoldable,
      isCollapsed: props.isCollapsed,
      savedImagesList: this.props.application.imageLoader.images,
      newImagesList: []
    };
    this.curImage = {
      fileName: null,
      fileUrl: null,
      order: null
    };
    this.tempStayOpen = false;
    this.testing_code = false;
  }

  handleMouseEnter = e => {
    if (this.testing_code) {
      console.log("image-menu: handleMouseEnter()");
    } ////////////////
    if (!this.tempStayOpen && this.state.isFoldable) {
      // if not staying open, then do >
      this.setState({ isCollapsed: false });
    }
  };
  handleMouseLeave = e => {
    if (this.testing_code) {
      console.log("image-menu: handleMouseLeave()");
    } ////////////////
    if (!this.tempStayOpen && this.state.isFoldable) {
      // if not staying open, then do >
      this.setState({ isCollapsed: true });
    }
  };
  handleUploadClick = e => {
    if (this.testing_code) {
      console.log("image-menu: handleUploadClick()");
    } ////////////////
    this.tempStayOpen = true;
  };
  handleImageOrder = orderedArray => {
    if (this.testing_code) {
      console.log("image-menu: handleImageOrder()");
    } ////////////////
    this.setState(
      prevState => {
        const newImagesList = prevState.newImagesList;
        const savedImages =
          newImagesList.length > 0 ? newImagesList : prevState.savedImagesList;
        const newImages = [];
        for (var i in orderedArray) {
          // find object in savedImages that matches orderedArray[i]
          const curFileName = orderedArray[i];
          const result = savedImages.find(
            image => image.fileName === curFileName
          );
          result.order = i;
          // add that object to newImages array
          newImages.push(result);
        }
        return {
          newImagesList: newImages
        };
      },
      () => {
        // save the newImagesList to the current application
        updateAllImages(
          this.state.newImagesList,
          "applications",
          this.props.appID
        );
      }
    );
  };

  areYouSure(reason) {
    return window.confirm("Are you sure you want to " + reason + "?");
  }

  removeImage = index => e => {
    if (this.testing_code) {
      console.log("image-menu: removeImage()");
    } ////////////////
    e.preventDefault();
    const ImagesList = this.state.newImagesList;
    const allImages =
      ImagesList.length > 0 ? ImagesList : this.state.savedImagesList;
    const curImage = allImages[index];
    if (allImages.length <= 5) {
      return window.alert("You cannot have less than 5 images.");
    } else {
      if (this.areYouSure(`delete the image ${curImage.fileName}`)) {
        this.props.removeImage(curImage).then(() => {
          this.setState(
            prevState => {
              const newImagesList = prevState.newImagesList;
              const orderedArray =
                newImagesList.length > 0
                  ? newImagesList
                  : prevState.savedImagesList;
              orderedArray.splice(index, 1);
              for (var i in orderedArray) {
                orderedArray[i].order = i;
              }
              return {
                newImagesList: orderedArray
              };
            },
            () => {
              // save the newImagesList to the current application
              updateAllImages(
                this.state.newImagesList,
                "applications",
                this.props.appID
              );
            }
          );
        });
      }
    }
  };

  addImage = e => {
    if (this.testing_code) {
      console.log("image-menu: addImage()");
    } ////////////////
    if (
      e.target.files.length >= 20 ||
      this.state.savedImagesList.length >= 20 ||
      e.target.files.length + this.state.savedImagesList.length >= 20 ||
      this.state.newImagesList.length >= 20 ||
      e.target.files.length + this.state.newImagesList.length >= 20
    ) {
      alert(
        "Please upload a maximum of 14 files. " +
          this.state.savedImagesList.length +
          " files are currently loaded."
      );
      e.preventDefault();
    } else {
      for (var curImg = 0; curImg < e.target.files.length; curImg++) {
        let reader = new FileReader();
        let file = e.target.files[curImg];

        let refId = uuid();
        let name = file.name;
        let ext = name.substr(name.lastIndexOf(".") + 1);
        let newName = `${refId}.${ext}`;

        reader.onload = () => {
          let curImage = { ...this.curImage };
          curImage.fileName = newName;
          curImage.fileUrl = reader.result;
          this.props.addImage(curImage).then(() => {
            // Add saved image to application in Firebase
            console.log("setting state");
            this.setState(
              prevState => {
                const newImagesList = prevState.newImagesList;
                const newImages =
                  newImagesList.length > 0
                    ? newImagesList
                    : prevState.savedImagesList;
                // let retrievedUrl = getImageUrl(curImage.fileName);
                curImage.fileUrl = null;
                curImage.order = newImages.length;
                newImages.push(curImage);
                return {
                  newImagesList: newImages
                };
              },
              () => {
                // save the newImagesList to the current application
                let newImagesList = this.state.newImagesList;
                getImageUrl(
                  newImagesList[newImagesList.length - 1].fileName
                ).then(url => {
                  newImagesList[newImagesList.length - 1].fileUrl = url;
                  updateAllImages(
                    this.state.newImagesList,
                    "applications",
                    this.props.appID
                  );
                });
              }
            );
            ////////
          });
          this.tempStayOpen = false;
        };
        reader.readAsDataURL(file);
      }
    }
  };

  render() {
    if (this.testing_code) {
      console.log("image-menu: render()");
    } ////////////////
    const newImagesList = this.state.newImagesList;
    const images =
      newImagesList.length > 0 ? newImagesList : this.state.savedImagesList;

    var imageOptionGroup = [];
    for (var image in images) {
      imageOptionGroup.push(
        <div
          className={style.image_container}
          key={image}
          data-id={images[image].fileName}
        >
          <div
            className={style.image}
            style={{ backgroundImage: "url(" + images[image].fileUrl + ")" }}
          />
          <div className={style.image_details}>
            <p>{images[image].fileName}</p>
            <div className={style.image_options}>
              <Eye />
              <EyeOff className={style.do_not_display} />
              <Trash2 onClick={this.removeImage(image)} />
              <Menu className={style.option_move} title={"Move to reorder"} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={classNames(
          style.image_menu,
          { [style.isFoldable]: this.state.isFoldable },
          { [style.isCollapsed]: this.state.isCollapsed }
        )}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className={style.padding}>
          <Sortable onChange={this.handleImageOrder}>
            {/* <Sortable> */}
            {imageOptionGroup}
          </Sortable>
          <div className={style.upload}>
            <input
              type="file"
              value=""
              multiple="multiple"
              accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
              onClick={this.handleUploadClick}
              onChange={this.addImage}
            />
            <button className={classNames("theme_button")}>
              {this.state.isCollapsed ? "+" : "add image"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

// function mapStateToProps(state) {
//   // // const id = ownProps.match.params.id;
//   // const applications = state.firestore.data.applications;
//   // const application = applications ? applications[id] : null;
//   // // console.log(state.firebase);
//   // return {
//   //   application: application,
//   //   appID: id
//   // };
// }

function mapDispatchToProps(dispatch) {
  return {
    addImage: data => dispatch(addImage(data)),
    removeImage: data => dispatch(removeImage(data)),
    updateApplication: (application, id) =>
      dispatch(updateApplication(application, id))
  };
}

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "applications" }])
)(ImageMenu);
