import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import Featured from "../../../blog-display/featured/featured";
import PostBanner from "../../../blog-display/post-banner/post-banner.js";
import QandA from "../../../artists/q-and-a/q-and-a.js";
import ImageMenu from "./image-menu/image-menu.js";

import {
  deleteApplication,
  denyApplication,
  removeAllImages,
  publishSubmission
} from "../../../../store/actions/application-actions.js";

class ReviewView extends React.Component {
  state = {
    isFoldable: true,
    isCollapsed: true
  };

  handleDelete = e => {
    // console.log("application-view: handleDelete(e)");
    if (this.areYouSure("delete application")) {
      Promise.all([
        this.props.removeAllImages(this.props.application, null)
      ]).then(() => {
        this.props.deleteApplication(this.props.application, this.props.appID);
        // then push to next application
      });
    }
  };

  handleDeny = e => {
    // console.log("application-view: handleDeny(e)");
    if (this.areYouSure("deny application")) {
      Promise.all([
        this.props.removeAllImages(this.props.application, null)
      ]).then(() => {
        this.props.denyApplication(this.props.application, this.props.appID);
        // then push to next application
      });
    }
  };

  handleSchedule = e => {
    console.log("review-view: handleSchedule(e)");
  };

  handlePublish = e => {
    console.log("review-view: handlePublish(e)");
    this.props.publishSubmission(this.props.application, this.props.appID);
  };

  areYouSure(reason) {
    return window.confirm("Are you sure you want to " + reason + "?");
  }

  render() {
    // this.props.match.params.id (ArtistID)
    const { application } = this.props;
    const appID = this.props.appID;

    if (application) {
      const questions = application.questions;
      const questionsGroup = [];

      for (var index in questions) {
        const curQ = questions[index];
        questionsGroup.push(
          <QandA info={curQ} key={curQ.id} editable={true} secret={true} />
        );
      }

      return (
        <div className={style.review_view}>
          <div className={style.app_contents}>
            <Featured artist={application} artistID={appID} />
            {/* 3 banner cards */}
            <div className={style.banner_group}>
              <PostBanner
                artist={application}
                key={application.id}
                defaultImage={0}
              />
              <PostBanner
                artist={application}
                key={application.id}
                defaultImage={1}
              />
              <PostBanner
                artist={application}
                key={application.id}
                defaultImage={2}
              />
            </div>

            {/* Questions for edit */}
            <div className={classNames(style.questions)}>{questionsGroup}</div>

            {/* Footer Button Bar */}
            <div className={style.button_bar}>
              <button className="theme_button" onClick={this.handleDelete}>
                Delete
              </button>
              <button className="theme_button" onClick={this.handleDeny}>
                Deny
              </button>
              <button className="theme_button" onClick={this.handleSchedule}>
                schedule
              </button>
              <button className="theme_button" onClick={this.handlePublish}>
                publish
              </button>
            </div>
          </div>

          {/* Image Menu */}
          <ImageMenu
            application={application}
            appID={appID}
            isFoldable={true}
            isCollapsed={true}
          />
        </div>
      );
    } else {
      return (
        <div>
          <p>Loading...</p>
        </div>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  const id = ownProps.match.params.id;
  const applications = state.firestore.data.applications;
  const application = applications ? applications[id] : null;
  // console.log(state.firebase);
  return {
    application: application,
    appID: id
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteApplication: (application, id) =>
      dispatch(deleteApplication(application, id)),
    denyApplication: (application, id) =>
      dispatch(denyApplication(application, id)),
    publishSubmission: (application, id) =>
      dispatch(publishSubmission(application, id)),
    removeAllImages: (application, folder) =>
      dispatch(removeAllImages(application, folder))
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "applications" }])
)(ReviewView);

// export default ApplicationView;
