import authReducer from "./auth-reducer.js";
import applicationReducer from "./application-reducer.js";
import artistReducer from "./artist-reducer.js";
import inputFieldReducer from "./input-field-reducer.js";
import questionReducer from "./question-reducer.js";
import adminReducer from "./admin-reducer.js";
import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import { connectRouter } from "connected-react-router";

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    admin: adminReducer,
    application: applicationReducer,
    artists: artistReducer,
    inputs: inputFieldReducer,
    questions: questionReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
  });
