import {
  applicationSelector,
  applicationFormSelector,
  isFetchingFormSelector
} from "../selectors/base.selectors";
import { selectNewFormInputs } from "../selectors/input-field.selectors";
import { submitApplication } from "../api/application-api";
import { fetchAllInputs } from "./input-field-actions";
import {
  fetchLockedQuestions,
  fetchRandomQuestions,
  fetchEndQuestions
} from "./question-actions";
import { selectNewFormQuestions } from "../selectors/question.selectors";

const ACTION_PREFIX = "@@application";
export const RESET_FORM = `${ACTION_PREFIX}/RESET_FORM`;
export const INITIALIZED_FORM = `${ACTION_PREFIX}/INITIALIZED_FORM`;
export const FORM_SUBMISSION_SUCCESS = `${ACTION_PREFIX}/FORM_SUBMISSION_SUCCESS`;
export const RESET_SUCCESS = `${ACTION_PREFIX}/RESET_SUCCESS`;
export const SET_COMPLETE = `${ACTION_PREFIX}/IS_COMPLETE`;
export const UPDATED_APPLICATION = `${ACTION_PREFIX}/UPDATED_APPLICATION`;
export const UPDATED_FORM = `${ACTION_PREFIX}/UPDATED_FORM`;
export const UPDATED_INPUT = `${ACTION_PREFIX}/UPDATED_INPUT`;
export const UPDATED_QUESTION = `${ACTION_PREFIX}/UPDATED_QUESTION`;
export const UPDATED_IMAGEUPLOADER = `${ACTION_PREFIX}/UPDATED_IMAGEUPLOADER`;
export const FETCHED = `${ACTION_PREFIX}/FETCHED`;
export const IS_FETCHING = `${ACTION_PREFIX}/IS_FETCHING`;
export const ADD_IMAGE = `${ACTION_PREFIX}/ADD_IMAGE`;
export const ADD_IMAGE_ERROR = `${ACTION_PREFIX}/ADD_IMAGE_ERROR`;
export const IS_SAVING_IMAGE = `${ACTION_PREFIX}/IS_SAVING_IMAGE`;

export const resetForm = () => ({ type: RESET_FORM });
export const resetSuccess = () => ({ type: RESET_SUCCESS });
export const setComplete = () => ({ type: SET_COMPLETE });
export const updateForm = update => ({ type: UPDATED_FORM, update });

export const initializeForm = () => (dispatch, getState) => {
  Promise.all([
    dispatch(fetchAllInputs()),
    dispatch(fetchLockedQuestions()),
    dispatch(fetchRandomQuestions()),
    dispatch(fetchEndQuestions())
  ]).then(() => {
    const state = getState();
    const inputs = selectNewFormInputs(state);
    const questions = selectNewFormQuestions(state);
    dispatch({ type: INITIALIZED_FORM, inputs, questions });
  });
};

export const submitForm = () => (dispatch, getState) => {
  console.log("submitted form");
  const state = getState();
  const application = applicationFormSelector(state);
  application.status = "inbox";
  application.submission_date = new Date().toLocaleString();
  application.tags = [];
  // application.id = null;
  return submitApplication(application).then(() => {
    dispatch({ type: FORM_SUBMISSION_SUCCESS });
    setTimeout(() => dispatch({ type: RESET_SUCCESS }), 5000);
  });
};

export const fetchForm = () => (dispatch, getState) => {
  // console.log("fetching form");
  const state = getState();
  if (isFetchingFormSelector(state)) {
    return null;
  }
  dispatch({ type: IS_FETCHING });
  // return state.then(form => dispatch({ type: FETCHED, form }));
  return state.application.form;
};

export const fetchApplication = () => (dispatch, getState) => {
  const state = getState();
  return state.application;
};

export const isFormValid = () => ({});

export const setAllValids = () => (dispatch, getState) => {
  // console.log("SetAllValids is being called");
  const state = getState();
  const form = applicationFormSelector(state);
  for (var index in form) {
    if (index === "inputs") {
      for (var input in form.inputs) {
        dispatch(updateInput(input, form.inputs[input].answer));
      }
    } else if (index === "questions") {
      for (var question in form.questions) {
        dispatch(updateQuestion(question, form.questions[question].answer));
      }
    } else if (index === "imageLoader") {
      const images = form.imageLoader.images;
      dispatch(updateImageLoader(images));
    }
  }
};

export const updateApplication = () => (dispatch, getState) => {
  const state = getState();
  const application = applicationSelector(state);
  const form = applicationFormSelector(state);
  var isValid = true;
  for (var index in form) {
    let curValid = form[index].valid;
    if (curValid !== undefined) {
      isValid = isValid ? curValid : isValid;
    } else if (index === "imageLoader") {
      let curImages = form.imageLoader.images;
      isValid = curImages.length >= 8 && curImages.length <= 14;
    } else if (index === "questions") {
      for (var subIndex in form.questions) {
        const curQuestion = form.questions[subIndex];
        if (curQuestion.status === "locked") {
          curValid = curQuestion.valid;
        } else {
          curValid = true;
        }
        isValid = isValid ? curValid : isValid;
      }
    }
  }
  const update = {
    ...application,
    isValid: !!isValid
  };
  dispatch({ type: UPDATED_APPLICATION, update });
};

export const updateInput = (index, answer) => (dispatch, getState) => {
  const state = getState();
  const form = applicationFormSelector(state);
  const inputs = form.inputs;
  const input = inputs[index];
  const required = input.required;
  const isEmpty = answer.length <= 0 ? true : false;
  const tempValid = required ? !isEmpty : true;
  const tempReason = tempValid ? "" : "Required";
  const update = {
    inputs: {
      [index]: {
        ...inputs[index],
        answer: answer,
        valid: tempValid,
        error: !tempValid,
        reason: tempReason,
        hasText: !isEmpty
      }
    }
  };
  dispatch({ type: UPDATED_INPUT, update });
};

export const updateQuestion = (index, answer) => (dispatch, getState) => {
  const state = getState();
  const form = applicationFormSelector(state);
  const question = form.questions[index];
  const required = question.status === "locked" ? true : false;
  const isEmpty = answer.length <= 0 ? true : false;
  let tempValid = required ? !isEmpty : true;
  let tempReason = tempValid ? "" : "Required";
  let tempLimit = question.limit;
  let tempLimitText = "character limit";
  if (!isEmpty && question.limit > 0) {
    tempLimit = question.limit - answer.length;
    tempValid = tempLimit >= 0;
    tempReason = tempValid ? "" : "Too many characters";
    tempLimitText =
      answer.length === 0
        ? "character limit"
        : tempValid
        ? "characters left"
        : "characters over";
  }
  const update = {
    questions: {
      [index]: {
        ...form.questions[index],
        answer: answer,
        valid: tempValid,
        error: !tempValid,
        reason: tempReason,
        newLimit: tempLimit,
        limitText: tempLimitText,
        display: true
      }
    }
  };
  dispatch({ type: UPDATED_QUESTION, update });
};

export const updateImageLoader = images => (dispatch, getState) => {
  let tempValid = !!images && images.length >= 8 && images.length <= 14;
  // tempValid = true; // bypass valid check for testing
  const tempReason = tempValid ? "" : "Requires 8-14 Images";
  const update = {
    imageLoader: {
      images: images,
      valid: tempValid,
      error: !tempValid,
      reason: tempReason
    }
  };
  dispatch({ type: UPDATED_IMAGEUPLOADER, update });
};

export const updateImages = allImages => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  // here
  console.log("updatingImages");
  const firebase = getFirebase();
  const curFolder = "application_images";
  for (var i in allImages) {
    let curImage = allImages[i];
    firebase
      .storage()
      .ref(`${curFolder}/${curImage.fileName}`) // application_images/lkjaljasjsie.jpg
      .getDownloadURL()
      .then(url => {
        // console.log(url);
        curImage.fileUrl = url;
        // then update, but can't do a firebase call inside a firebase call
      });
  }
};

export const uploadImages = (images, folder) => dispatch => {
  console.log(`uploading ${images.length} images`);
  return Promise.all(
    // dispatch(isSavingImage(img))
    images.map(img => dispatch(addImage(img, folder)))
  );
};

//Image Uploader
export const uploadCurImage = (curImage, curFolder) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  Promise.all([dispatch(addImage(curImage, curFolder))]).then(() => {
    console.log("Get Download Url");
    firebase
      .storage()
      .ref(`${curFolder}/${curImage.fileName}`)
      .getDownloadURL()
      .then(url => {
        // console.log(url);
        return url;
      });
  });
};

export const isSavingImage = image => (dispatch, getState) => {
  // Set application image isSaving to TRUE
  console.log(`is saving image ${image.order}`);
  dispatch({ type: IS_SAVING_IMAGE, image });
};

/* action creator */
export function addImage(image, folder) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    if (!folder) {
      folder = "application_images";
      // folder = "application_images_testing";
    }

    return firebase
      .storage()
      .ref()
      .child(`${folder}/${image.fileName}`)
      .putString(image.fileUrl, "data_url")
      .then(() => {
        image.isSaving = true;
        dispatch({ type: ADD_IMAGE, image });
        console.log(image);
      })
      .catch(err => {
        dispatch({ type: ADD_IMAGE_ERROR, err });
      });
  };
}

/* action creator */
export function removeAllImages(application, folder) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const images = application.imageLoader.images;
    return Promise.all([
      images.map(image => dispatch(removeImage(image, folder)))
    ]);
  };
}

/* action creator */
export function removeImage(image, folder) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    if (!folder) {
      folder = "application_images";
    }
    return firebase
      .storage()
      .ref()
      .child(`${folder}/${image.fileName}`)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_IMAGE", image });
      })
      .catch(err => {
        console.log(err);
        dispatch({ type: "DELETE_IMAGE_ERROR", err });
      });
  };
}

/////////////////////////////

/* action creator */
export function createApplication(application, images) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return Promise.all([
      ...images.map(image => dispatch(addImage(image))),
      firestore.collection("applications").add({
        ...application,
        status: "inbox",
        submission_date: new Date().toLocaleString()
      })
    ])
      .then(() => {
        dispatch({ type: "CREATE_APPLICATION", application });
        // dispatch(push(`/application/${id}`));
      })
      .catch(err => {
        dispatch({ type: "CREATE_APPLICATION_ERROR", err });
      });
  };
}

/* action creator */
export function deleteApplication(application, id) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("applications")
      .doc(id)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE_APPLICATION", application, id });
        console.log("deleted application");
      })
      .catch(err => {
        dispatch({ type: "DELETE_APPLICATION_ERROR", err });
      });
  };
}

/* action creator */
// export function updateApplication(application, id) {
//   return (dispatch, getState, { getFirebase, getFirestore }) => {
//     const firestore = getFirestore();
//     // const firebase = getFirebase();
//     firestore
//       .collection("applications")
//       .doc(id)
//       .update({
//         ...application
//       })
//       .then(() => {
//         dispatch({ type: "UPDATE_APPLICATION", application, id });
//         console.log("updated application");
//       })
//       .catch(err => {
//         dispatch({ type: "UPDATE_APPLICATION_ERROR", err });
//       });
//   };
// }

/* action creator */
export function acceptApplicationForReview(application, id) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    // const firebase = getFirebase();
    firestore
      .collection("applications")
      .doc(id)
      .update({
        ...application,
        status: "review"
      })
      .then(() => {
        dispatch({ type: "ACCEPT_APPLICATION_FOR_REVIEW", application, id });
        console.log("accepted application for review");
      })
      .catch(err => {
        dispatch({ type: "ACCEPT_APPLICATION_FOR_REVIEW_ERROR", err });
      });
  };
}

/* action creator */
export function denyApplication(application, id) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    // make images super small preview images
    return Promise.all([
      dispatch(deleteApplication(application, id)),
      firestore.collection("denied_applications").add({
        ...application,
        status: "denied"
      })
    ])
      .then(() => {
        dispatch({ type: "DENY_APPLICATION", application, id });
        console.log(
          "application has been denied and move to denied_applications"
        );
      })
      .catch(err => {
        dispatch({ type: "DENY_APPLICATION_ERROR", err });
      });
  };
}

export function publishSubmission(application, id) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return Promise.all([
      // keep images in database
      firestore.collection("artists").add({
        ...application,
        status: "published",
        published_date: new Date().toLocaleString()
      })
    ])
      .then(() => {
        dispatch({ type: "CREATE_ARTIST", application });
        dispatch(deleteApplication(application, id));
        // push to new application OR show success message
      })
      .catch(err => {
        dispatch({ type: "CREATE_ARTIST_ERROR", err });
      });
  };
}
