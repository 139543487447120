import React from "react";
import style from "./style.module.scss";

class FormIntro extends React.Component {
  render() {
    return (
      <div className={style.form_intro}>
        <h1>Submission Form</h1>
        <h2>> Start Here</h2>
        <p>
          Archive-ism is a digital art space built to help contemporary artists
          at all levels share their work and ideas with the world.
        </p>
        <p>
          This submission form serves as an open call for all artists. Works
          from any visual medium or discipline are eligible for consideration.
          You must submit a series of{" "}
          <span className={style.form_intro_numbers}>8-14</span> photos that
          highlight your recent works and answer the list of interview questions
          below. Help fill the archives with some soul. Showcase how your mind
          works and the way you think about art.
        </p>
        <p>
          Artists will be selected based on merit, originality, quality of
          presentation, and how well their voice fits into the curated direction
          of this space.
        </p>
        <p>
          If your submission is chosen for publication, we may choose between
          several of your answers or list all of them, and could select from
          works displayed on your website in addition to those that are
          submitted. Although we may not be able to respond to all submissions,
          if your work is selected you will receive an email letting you know.
        </p>
        <p>
          <strong>
            > Please note that a minimum payment of{" "}
            <span className={style.form_intro_numbers}>$2</span> will be
            required to submit.
          </strong>
        </p>
        <p>
          Archive-ism is an independent artist-run contemporary art resource
          dedicated to connecting great artists with art lovers around the
          world. We couldn’t provide this opportunity without the help of
          patrons like you. Continue to archive your work, your mind, and your
          evolution towards mastery. Cheers!
        </p>
      </div>
    );
  }
}

export default FormIntro;
