import React from "react";
import style from "./style.module.scss";
import uuid from "uuid/v4";
import classNames from "classnames";
import { XSquare } from "react-feather";

class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allImages: [],
      allImageNames: []
    };
    this.curImage = {
      fileName: null,
      fileUrl: null,
      order: null,
      saved: false,
      isSaving: false
    };
    this.imagesContainer = [];
    this.testing_code = false;
  }

  sendAllImages() {
    if (this.testing_code) {
      console.log("image-uploader: sendAllImages()");
    } ////////////////
    this.props.setAllImages(this.state.allImages, this.state.allImageNames);
  }

  addImage = e => {
    if (this.testing_code) {
      console.log("image-uploader: addImage()");
    } ////////////////
    if (e.target.files.length > 14 || this.state.allImages.length >= 14) {
      alert("Please upload a maximum of 14 files.");
      e.preventDefault();
    } else {
      for (var curImg = 0; curImg < e.target.files.length; curImg++) {
        let reader = new FileReader();
        let file = e.target.files[curImg];
        let refId = uuid();
        let ext = file.name.substr(file.name.lastIndexOf(".") + 1);
        let newName = `${refId}.${ext}`;

        reader.onload = () => {
          let curImage = { ...this.curImage };
          curImage.fileName = newName;
          curImage.fileUrl = reader.result;
          curImage.order = this.state.allImages.length;
          this.setState(prevState => ({
            allImages: [...prevState.allImages, curImage],
            allImageNames: [
              ...prevState.allImageNames,
              {
                fileName: curImage.fileName,
                order: curImage.order,
                saved: false,
                isSaving: false
              }
            ]
          }));
          this.sendAllImages();
        };
        reader.readAsDataURL(file);
      }
    }
  };

  handleRemove = index => e => {
    if (this.testing_code) {
      console.log("image-uploader: handleRemove()");
    } ////////////////
    e.preventDefault();
    this.setState(
      prevState => {
        const newImages = prevState.allImages;
        const newImageNames = prevState.allImageNames;
        newImages.splice(index, 1);
        newImageNames.splice(index, 1);
        for (var i in newImages) {
          newImages[i].order = i;
          newImageNames[i].order = i;
        }
        return {
          allImages: newImages,
          allImageNames: newImageNames
        };
      },
      () => {
        this.sendAllImages();
      }
    );
  };

  render() {
    if (this.testing_code) {
      console.log("image-uploader: render()");
    } ////////////////
    this.imagesContainer = [];
    for (var imageIndex in this.state.allImages) {
      let all = this.state.allImages;
      this.imagesContainer.push(
        <div
          className={style.image}
          key={imageIndex}
          id={imageIndex}
          style={{
            backgroundImage: "url(" + all[imageIndex].fileUrl + ")"
            // backgroundImage: tempFile
          }}
        >
          <button onClick={this.handleRemove(imageIndex)}>
            <XSquare className={style.icon} />
          </button>
        </div>
      );
    }

    return (
      <div className={style.image_uploader}>
        <h1
          className={classNames(style.header, {
            [style.error]: this.props.error
          })}
        >
          Upload Images{this.props.error ? " - " + this.props.reason : null}
        </h1>
        <p className={style.subtext}>
          Include 8-14 images of your work. Make sure the resolution of your
          images is high enough to fit across 1000 pixels without blurring.
        </p>
        <div className={style.upload}>
          <input
            type="file"
            value=""
            multiple="multiple"
            accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
            onChange={this.addImage}
          />
          <button className={classNames("theme_button")}>add image(s)</button>
        </div>
        <div className={style.images}>{this.imagesContainer}</div>
      </div>
    );
  }
}

export default ImageUploader;
