import React from "react";
import style from "./style.module.scss";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

class Sidebar extends React.Component {
  render() {
    const apps = this.props.applications;
    if (apps) {
      var inboxApps = Object.values(apps).filter(function(app) {
        return !!app ? app.status === "inbox" : null;
      });
      var reviewApps = Object.values(apps).filter(function(app) {
        return !!app ? app.status === "review" : null;
      });
    }
    const TempInboxNum = inboxApps ? Object.keys(inboxApps).length : "-";
    const TempReviewNum = reviewApps ? Object.keys(reviewApps).length : "-";

    return (
      <div className={style.admin_view}>
        {/* <div className={style.dashboard}> */}
        <div className={style.sidebar}>
          <ul>
            <li>
              <NavLink to="/admin/inbox" activeClassName={style.active}>
                Inbox ({TempInboxNum})
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/review" activeClassName={style.active}>
                Review ({TempReviewNum})
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/scheduled" activeClassName={style.active}>
                Scheduled
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/form" activeClassName={style.active}>
                Form
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/codes" activeClassName={style.active}>
                Codes
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/messages" activeClassName={style.active}>
                Messages
              </NavLink>
            </li>
          </ul>
        </div>
        {this.props.children}
        {/* </div> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    applications: state.firestore.data.applications
  };
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "applications", orderBy: "submission_date" }])
)(Sidebar);
