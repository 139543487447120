import React from "react";
import TextInput from "../../../application-form/text-input/text-input.js";
import { connect } from "react-redux";
import { signIn } from "../../../../store/actions/auth-actions.js";
import classNames from "classnames";
import { Redirect } from "react-router-dom";
import { push } from "connected-react-router";

import style from "./style.module.scss";

class SignIn extends React.Component {
  state = {
    email: "",
    password: "",
    redirect: false
  };

  setRedirect = () => {
    this.setState({
      redirect: true
    });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/admin/" />;
    }
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.signIn(this.state).then(() => {
      console.log("after submit");
      this.props.toAdmin();
    });
    // this.setRedirect();
  };

  checkPush = e => {
    console.log("next stop, toAdmin");
  };

  componentWillUpdate(nextProps) {
    // this.setRedirect();
    // this.renderRedirect();
    // if (this.props.auth.uid != null) {
    //   console.log("right here mate!");
    // }
    // const { hasUser, toAdmin } = this.props;
    // if (!hasUser && nextProps.hasUser) {
    //   toAdmin();
    // }
  }

  render() {
    return (
      <div className={style.signin}>
        {this.renderRedirect()}
        <form onSubmit={this.handleSubmit} className={style.form_element}>
          <TextInput
            inputs={[
              { key: "email", placeholder: "Email", type: "text" },
              { key: "password", placeholder: "Password", type: "password" }
            ]}
            onChange={this.handleChange}
          />
          <button className={classNames("theme_button")}>sign in</button>
          {this.props.authError ? <p>{this.props.authError}</p> : null}
        </form>
        <button
          onClick={this.setRedirect}
          className={classNames("theme_button")}
        >
          push check
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signIn: creds => dispatch(signIn(creds)),
    toAdmin: () => dispatch(push(`/admin`))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
