import React, { Fragment } from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import { BatteryCharging } from "react-feather";

class EmptyLabel extends React.Component {
  render() {
    const { isFoldable } = this.props;
    const { isCollapsed } = this.props;

    return (
      <div
        className={classNames(
          style.empty_label,
          { [style.isFoldable]: isFoldable },
          { [style.isCollapsed]: isCollapsed }
        )}
      >
        {isCollapsed ? (
          <Fragment>
            <BatteryCharging />
          </Fragment>
        ) : (
          <Fragment>
            <BatteryCharging />
            <h1>Recharging</h1>
          </Fragment>
        )}
      </div>
    );
  }
}

export default EmptyLabel;
