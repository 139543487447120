import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import PostBanner from "../post-banner/post-banner.js";

export default function PostList({ artists, detail }) {
  let header = "";
  if (detail === "latest") {
    header = "latest";
  } else if (detail === "another") {
    header = "another";
  }
  return (
    <div className={classNames(style.container_l, style.post_list)}>
      <div className={style.post_list_header}>
        <h1>{header}</h1>
        <a href="/allartists">all artists ></a>
      </div>
      <div className={style.post_list_banners}>
        {artists &&
          artists.map(artist => {
            return <PostBanner artist={artist} key={artist.id} />;
          })}
      </div>
      {detail === "latest"
        ? // <div className={style.post_list_footer}>
          //   <h1>see more +</h1>
          // </div>
          null
        : null}
    </div>
  );
}
