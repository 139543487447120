import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import autosize from "autosize";

class QuestionInput extends React.Component {
  constructor(props) {
    super(props);
    this.textarea = React.createRef();
  }

  handleFocus = e => {
    // console.log("question-input: handleFocus(e)");
  };

  handleBlur = e => {
    // console.log("question-input: onBlur(e)");
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  handleChange = e => {
    // console.log("question-input: onChange(e)");
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  componentDidMount() {
    autosize(this.textarea.current);
  }

  render() {
    // console.log("question-input: render()");
    const { question } = this.props;
    let error = question.error;
    let reason = question.reason;
    let limit = question.limit;
    let newLimit = question.newLimit;
    let limitText = question.limitText;
    let status = question.status;

    return (
      <div className={style.question_input}>
        <h1
          className={classNames(style.question, {
            [style.error]: error
          })}
        >
          {question.question}
        </h1>
        {question.subtext && (
          <p className={style.subtext}>{question.subtext}</p>
        )}

        <div className={style.input}>
          <textarea
            ref={this.textarea}
            id={question.id}
            placeholder="..."
            className={classNames(style.empty, {
              [style.error]: error
            })}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          />
          <div className={style.extra_labels}>
            {error ? (
              <p
                className={classNames(style.error_message, {
                  [style.error]: error
                })}
              >
                {reason}
              </p>
            ) : status === "locked" ? (
              <p className={classNames(style.note_message)}>Required</p>
            ) : null}

            {!!limit && limit.length > 0 ? (
              <p
                className={classNames(style.limit, {
                  [style.error]: error
                })}
              >
                {newLimit} {limitText}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionInput;
