import React from "react";
import { Switch, Route } from "react-router-dom";
import ScrollToTop from "./components/layout/scroll-to-top/scroll-to-top.js";

import BlogDisplay from "./components/blog-display/blog-display.js";
import ApplicationForm from "./components/application-form/application-form.js";
import ArtistDetails from "./components/artists/artist-details.js";
import AllArtists from "./components/all-artists/all-artists.js";
import SignIn from "./components/admin/authentication/signin/signin.js";
import AdminRoutes from "./components/admin/admin-routes/admin-routes.js";
import Navbar from "./components/layout/navbar/navbar.js";
import Backdrop from "./components/layout/backdrop/backdrop.js";
import Donate from "./components/donate/donate.js";

import "./styles/global.css";
import MenuDrawer from "./components/layout/menu-drawer/menu-drawer.js";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuDrawerOpen: false
    };
  }

  handleMenuDrawerClose = e => {
    this.setState({ menuDrawerOpen: false });
  };

  handleMenuDrawerOpen = e => {
    this.setState({ menuDrawerOpen: true });
  };

  render() {
    return (
      <ScrollToTop>
        <MenuDrawer
          menuDrawerOpen={this.state.menuDrawerOpen}
          handleMenuDrawerClose={this.handleMenuDrawerClose}
        />
        <Backdrop
          menuDrawerOpen={this.state.menuDrawerOpen}
          handleMenuDrawerClose={this.handleMenuDrawerClose}
        />
        <Switch>
          <Navbar
            menuDrawerOpen={this.state.menuDrawerOpen}
            handleMenuDrawerOpen={this.handleMenuDrawerOpen}
          >
            <Route exact path="/" component={BlogDisplay} />
            <Route path="/submit" component={ApplicationForm} />
            <Route path="/artist/:id" component={ArtistDetails} />
            <Route path="/allartists" component={AllArtists} />
            <Route path="/signin" component={SignIn} />
            <Route path="/admin" component={AdminRoutes} />
            <Route path="/donate" component={Donate} />
          </Navbar>
        </Switch>
      </ScrollToTop>
    );
  }
}

export default App;
