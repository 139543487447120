import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ApplicationView from "../inbox/application-view/application-view.js";
import ReviewView from "../review/review-view/review-view.js";
import Sidebar from "../sidebar/sidebar.js";
import Inbox from "../inbox/inbox.js";
import Review from "../review/review.js";
import FormSettings from "../form-settings/form-settings.js";
import SubmissionCodes from "../submission-codes/submission-codes.js";
import { connect } from "react-redux";

// export default function AdminRoutes({ match }) {
class AdminRoutes extends React.Component {
  render() {
    const match = this.props.match;
    if (!this.props.auth.uid) {
      console.log("REDIRECTING");
      return <Redirect to="/signin" />;
    }

    return (
      <Sidebar>
        <Switch>
          <Route
            path={`${match.path}/inbox`}
            render={({ match }) => (
              <Inbox>
                <Switch>
                  <Route
                    path={`${match.path}/:id`}
                    component={ApplicationView}
                  />
                </Switch>
              </Inbox>
            )}
          />
          <Route
            path={`${match.path}/review`}
            render={({ match }) => (
              <Review>
                <Switch>
                  <Route path={`${match.path}/:id`} component={ReviewView} />
                </Switch>
              </Review>
            )}
          />
          <Route
            path={`${match.path}/form`}
            render={({ match }) => <FormSettings />}
          />
          <Route
            path={`${match.path}/codes`}
            render={({ match }) => <SubmissionCodes />}
          />
          <Redirect from={match.path} to={`${match.path}/inbox`} />
        </Switch>
      </Sidebar>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.firebase.auth
  };
}

export default connect(mapStateToProps)(AdminRoutes);
