import { createSelector } from "reselect";
import {
  lockedQuestionItemsSelector,
  randomQuestionItemsSelector,
  endQuestionItemsSelector
} from "./base.selectors";

export const selectNewFormQuestions = createSelector(
  [
    lockedQuestionItemsSelector,
    randomQuestionItemsSelector,
    endQuestionItemsSelector
  ],
  (lockedItems, randomItems, endItems) => {
    const lockedLength = Object.keys(lockedItems).length;
    const randomLength = Object.keys(randomItems).length;
    const endLength = Object.keys(endItems).length;
    if (lockedLength > 0 && randomLength > 0 && endLength > 0) {
      const newFormQuestions = {};
      let index = 0;

      for (const key in lockedItems) {
        newFormQuestions[index] = Object.assign({}, lockedItems[key]);
        newFormQuestions[index].id = key;
        newFormQuestions[index].answer = "";
        newFormQuestions[index].valid = null;
        const limit = lockedItems[key].limit;
        newFormQuestions[index].limit = limit ? limit : null;
        newFormQuestions[index].newLimit = limit ? limit : null;
        newFormQuestions[index].limitText = limit ? "character limit" : null;
        index++;
      }

      var arr = [];
      while (arr.length < randomLength && arr.length < 12) {
        var randNum = Math.floor(Math.random() * randomLength) + 1;
        if (arr.indexOf(randNum) > -1) continue;
        arr[arr.length] = randNum;
        const curQue = Object.entries(randomItems)[randNum - 1];
        newFormQuestions[index] = Object.assign({}, curQue[1]);
        newFormQuestions[index].id = curQue[0];
        newFormQuestions[index].answer = "";
        newFormQuestions[index].valid = null;
        const limit = curQue[1].limit;
        newFormQuestions[index].limit = limit ? limit : null;
        newFormQuestions[index].newLimit = limit ? limit : null;
        newFormQuestions[index].limitText = limit ? "character limit" : null;
        index++;
      }

      for (const key in endItems) {
        newFormQuestions[index] = Object.assign({}, endItems[key]);
        newFormQuestions[index].id = key;
        newFormQuestions[index].answer = "";
        newFormQuestions[index].valid = null;
        const limit = endItems[key].limit;
        newFormQuestions[index].limit = limit ? limit : null;
        newFormQuestions[index].newLimit = limit ? limit : null;
        newFormQuestions[index].limitText = limit ? "character limit" : null;
        index++;
      }

      return newFormQuestions;
    }
  }
);
