import { createSelector } from "reselect";

import { applicationFormSelector, applicationSelector } from "./base.selectors";

export const selectApplication = createSelector(
  [applicationSelector],
  application => {
    return application;
  }
);

export const isFormValid = createSelector(
  [applicationFormSelector],
  form => {
    var canGo = true;
    // var curForm = Object.assign({}, form);

    // for (var curEl in curForm) {
    //   if (curEl === "images") {
    //   } else if (curEl === "questions") {
    //     for (var curQue in curForm[curEl]) {
    //       if (curForm[curEl][curQue].valid === false) {
    //         // canGo = false;
    //       }
    //     }
    //   } else {
    //     if (curForm[curEl].valid === false || curForm[curEl].valid === null) {
    //       canGo = false;
    //     }
    //   }
    // }
    // console.log(`--------${canGo}--------`);
    return canGo;
  }
);
