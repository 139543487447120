import React from "react";
import Queue from "../queue/queue.js";
import style from "./style.module.scss";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { push } from "connected-react-router";

class Inbox extends React.Component {
  state = {
    firstElementId: ""
  };

  pushNextApp(comment) {
    if (
      !!this.props.applications &&
      this.props.applications.length > 0 &&
      this.state.firstElementId !== this.props.applications[0].id
    ) {
      console.log(comment);
      this.setState({ firstElementId: this.props.applications[0].id }, () => {
        this.props.nextApplication(this.state.firstElementId);
      });
    }
  }

  componentDidMount() {
    this.pushNextApp("did mount");
  }

  componentWillReceiveProps() {
    this.pushNextApp("will receive props");
  }

  componentDidUpdate() {
    this.pushNextApp("did update");
  }

  render() {
    const apps = this.props.applications;
    return (
      <div className={style.inbox}>
        <Queue applications={apps} isFoldable={true} />
        {this.props.children}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const applications = state.firestore.ordered.applications;
  if (applications) {
    applications.sort(function(a, b) {
      return new Date(a.submission_date) - new Date(b.submission_date);
    });
    var inboxApps = Object.values(applications).filter(function(app) {
      return app.status === "inbox";
    });
  }
  return {
    applications: inboxApps
  };
}

function mapDispatchToProps(dispatch) {
  return {
    nextApplication: id => {
      dispatch(push("/admin/inbox/" + id));
    }
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([
    { collection: "applications", orderedBy: "submission_date" }
  ])
)(Inbox);
