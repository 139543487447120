import React from "react";
import style from "./style.module.scss";

class Footer extends React.Component {
  render() {
    return (
      <div className={style.footer}>
        <div className={style.footer_links}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/archive_ism/"
          >
            instagram
          </a>
          <a href="mailto:info@archive-ism.com">email</a>
          <a href="/submit">submit</a>
          <a href="/submit">donate</a>
          <a href="mailto:info@archive-ism.com">ideas?</a>
        </div>
        <div className={style.footer_message}>
          <p>
            Archive-ism is an independently run digital art space and
            contemporary art resource helping artists to spread their ideas, and
            we could use your support!
          </p>
          <a href="/submit">donate +</a>
        </div>
        <div className={style.footer_copyright}>
          <span>
            <span className={style.unicode}>{"\u00A9"}</span>
            {new Date().getFullYear().toString()}
          </span>
        </div>
      </div>
    );
  }
}

export default Footer;
