import React, { Fragment } from "react";
// import { connect } from "react-redux";
import style from "./style.module.scss";
import classNames from "classnames";

// import {
//   applicationFormSelector,
//   applicationSelector
// } from "../../../store/selectors/base.selectors";

import { Loader, Image, Check } from "react-feather";

class SubmittingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.error = false;
    this.error_content = [];
    this.testing_code = false;
  }
  render() {
    const app = this.props.application;
    var images =
      app.form.imageLoader.images.length > 0
        ? app.form.imageLoader.images
        : null;
    var name =
      !!app.form.inputs.firstname &&
      app.form.inputs.firstname.answer !== undefined &&
      app.form.inputs.firstname.answer.length > 0
        ? app.form.inputs.firstname.answer
        : null;

    console.log(app);
    // images = ["a", "b", "c", "a", "b", "c", "a", "b", "c", "a", "b", "c"];

    var images_loading = [];
    if (images) {
      images_loading.push(
        <Loader className={classNames(style.spin, style.electric)} />
      );
      // for (var i in images) {
      //   console.log(images[i].isSaving);
      //   console.log(images[i].saved);
      //   images_loading.push(
      //     <div key={i}>
      //       <Loader
      //         className={classNames(style.spin, style.electric, {
      //           [style.hide]: !images[i].isSaving || images[i].saved
      //         })}
      //       />
      //       <Image
      //         className={classNames({
      //           [style.hide]: images[i].isSaving || images[i].saved
      //         })}
      //       />
      //       <Check
      //         className={classNames(style.green, {
      //           [style.hide]: !images[i].saved
      //         })}
      //       />
      //     </div>
      //   );
      // }
    } else {
      this.error = true;
      this.error_content = [
        <div key={0}>
          <h1>Uh oh!</h1>
          <h2>Looks like something went wrong...</h2>
          <p>
            Unfortunately, no images were loaded. Please try submitting again.
          </p>
          <p>
            If something is wrong with our code, we need your help to fix the
            problem. Please send an email to{" "}
            <a href="mailto:info@archive-ism.com">info@archive-ism.com</a>{" "}
            explaining what happened. We appreciate you! Let us help. :)
          </p>
        </div>
      ];
    }

    // console.log(images_loading);

    if (app.isComplete) {
      return (
        <div className={classNames(style.container_s, style.completion_module)}>
          <div>
            <h1>Submission Complete</h1>
            <h2>{name ? name : "Hey"}, we have received your application!</h2>
            <p>Thank you wonderful human.</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classNames(style.container_s, style.completion_module)}>
          {this.error ? (
            <Fragment>{this.error_content}</Fragment>
          ) : (
            <div>
              {/* <h1>Submitting...</h1>
            <h1>Give us a sec...</h1> */}
              <h1>Please wait</h1>
              <h2>{name ? name : "Hey"}, we're saving your application.</h2>
              <p>
                Please do not leave this page until the submission process is
                complete.
              </p>
              <div className={style.images_loading}>{images_loading}</div>
            </div>
          )}
        </div>
      );
    }
  }
}

// function mapStateToProps(state, ownProps) {
//   // console.log(ownProps);
//   return {
//     application: applicationSelector(state),
//     form: applicationFormSelector(state)
//   };
// }

// export default connect(
//   mapStateToProps,
//   null
// )(SubmittingView);

export default SubmittingView;
