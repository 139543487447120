import React, { Component } from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import { CardElement } from "react-stripe-elements";
import TextInput from "../application-form/text-input/text-input.js";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

import { checkCode } from "../../store/actions/submission-code-actions.js";
import { Check, Slash } from "react-feather";

// import {
//   cardError,
//   submitCharge
// } from "../../store/actions/checkout-actions.js";

class DonateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      isDonating: false,
      usingCode: false,
      codeSuccess: false,
      codeError: false,
      donate: 0,
      payment: 2,
      total: 2,
      code_icon: null
    };
    this.total = 2;
    this.code = "";
  }

  handleClick = e => {
    this.setState({
      [e.target.id]: true
    });
  };

  handleChange = e => {
    if (e.target.id === "code") {
      // Check if code is valid
      let valid = this.props.checkCode(e.target.value);
      if (valid !== undefined) {
        if (valid) {
          this.total = 0 + this.state.donate;
          this.code = e.target.value;
          this.setState(
            {
              codeSuccess: true,
              codeError: false,
              payment: 0,
              total: 0 + this.state.donate,
              code_icon: <Check className={style.green} />
            },
            () => {
              this.props.onChange(e, this.total, this.code);
            }
          );
        } else {
          this.total = 2 + this.state.donate;
          this.code = "";
          this.setState(
            {
              codeSuccess: false,
              codeError: true,
              payment: 2,
              total: 2 + this.state.donate,
              code_icon: <Slash className={style.red} />
            },
            () => this.props.onChange(e, this.total, this.code)
          );
        }
      } else if (this.state.code_icon !== null) {
        this.total = 2 + this.state.donate;
        this.code = "";
        this.setState(
          {
            codeSuccess: false,
            codeError: false,
            payment: 2,
            total: 2 + this.state.donate,
            code_icon: null
          },
          () => this.props.onChange(e, this.total, this.code)
        );
      }
    } else {
      // const re = /^[0-9\b]+$/;
      // if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ [e.target.id]: e.target.value }, () => {
        this.props.onChange(e, this.total, this.code);
      });
      // }
    }
  };

  newTotal() {
    this.total = Number(this.state.payment) + Number(this.state.donate);
    return this.total;
  }

  render() {
    this.total = Number(this.state.payment) + Number(this.state.donate);

    if (this.state.complete) {
      return <h1>Charge Complete</h1>;
    } else {
      return (
        <div className={style.card_input}>
          <h1 className={style.header}>{this.props.header}</h1>
          <p className={style.subtext}>You are amazing!</p>

          <div className={classNames(style.donate_input)}>
            <TextInput
              inputs={{
                key: "donate",
                placeholder: "Donation",
                type: "number",
                value: "10.00",
                max: 10
              }}
              onChange={this.handleChange}
            />
            <div className={style.total}>
              <p className={style.label}>Total:</p>
              <p className={style.amount}>${this.total.toFixed(2)}</p>
            </div>
          </div>

          <CardElement
            className={classNames(style.card_group, {
              [style.fade]: this.state.codeSuccess && !this.state.isDonating
            })}
            style={{
              base: {
                color: "#000000",
                fontSmoothing: "antialiased",
                fontFamily: '"Signika", monospace',
                fontSize: "18px",
                fontWeight: "600",
                "::placeholder": {
                  color: "#bbbbbb"
                }
              },
              invalid: {
                color: "#d56a6a",
                iconColor: "#d56a6a"
              }
            }}
          />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  const codes = state.firestore.ordered.submission_codes;
  return {
    codes: codes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkCode: code => dispatch(checkCode(code))
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "submission_codes" }])
)(DonateForm);
