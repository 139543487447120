import {
  IS_FETCHING_ALL,
  IS_FETCHING_LOCKED,
  IS_FETCHING_RANDOM,
  IS_FETCHING_END,
  FETCHED_ALL,
  FETCHED_LOCKED,
  FETCHED_RANDOM,
  FETCHED_END
} from "../actions/question-actions.js";

const defaultState = {
  isFetchingAll: false,
  isFetchingLocked: false,
  isFetchingRandom: false,
  isFetchingEnd: false,
  all: {},
  locked: {},
  random: {},
  end: {}
};

export default function questionReducer(state = defaultState, action) {
  switch (action.type) {
    case IS_FETCHING_ALL:
      return { ...state, isFetchingAll: true };
    case IS_FETCHING_LOCKED:
      return { ...state, isFetchingLocked: true };
    case IS_FETCHING_RANDOM:
      return { ...state, isFetchingRandom: true };
    case IS_FETCHING_END:
      return { ...state, isFetchingEnd: true };
    case FETCHED_ALL:
      return { ...state, isFetchingAll: false, all: action.questions };
    case FETCHED_LOCKED:
      return { ...state, isFetchingLocked: false, locked: action.questions };
    case FETCHED_RANDOM:
      return { ...state, isFetchingRandom: false, random: action.questions };
    case FETCHED_END:
      return { ...state, isFetchingEnd: false, end: action.questions };

    ///////////////////////////////////
    case "CREATE_QUESTION":
      console.log("create question", action.question);
      return state;
    case "CREATE_QUESTION_ERROR":
      console.log("create question error", action.err);
      return state;
    default:
      return state;
  }
}
