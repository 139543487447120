import React from "react";
import style from "./style.module.scss";
import QueueCard from "./queue-card/queue-card.js";
import EmptyLabel from "./empty-label/empty-label.js";
import classNames from "classnames";

class Queue extends React.Component {
  state = {
    isFoldable: this.props.isFoldable,
    isCollapsed: this.props.isFoldable,
    firstElementId: ""
  };

  handleMouseOver = e => {
    this.state.isFoldable && this.setState({ isCollapsed: false });
  };
  handleMouseLeave = e => {
    this.state.isFoldable && this.setState({ isCollapsed: true });
  };

  render() {
    return (
      <div
        className={classNames(
          style.queue,
          { [style.isFoldable]: this.state.isFoldable },
          { [style.isCollapsed]: this.state.isCollapsed }
        )}
        onMouseOver={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className={style.padding}>
          {this.props.applications &&
            this.props.applications.map(application => {
              return (
                <QueueCard
                  application={application}
                  key={application.id}
                  isFoldable={this.state.isFoldable}
                  isCollapsed={this.state.isCollapsed}
                />
              );
            })}
          {this.props.applications && this.props.applications.length === 0 ? (
            <EmptyLabel
              isFoldable={this.state.isFoldable}
              isCollapsed={this.state.isCollapsed}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default Queue;
