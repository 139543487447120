import React, { Fragment } from "react";
import style from "./style.module.scss";
import classNames from "classnames";

// import PostList from "./post-list/post-list.js";
import Footer from "../layout/footer/footer.js";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

class AllArtists extends React.Component {
  render() {
    const { artists } = this.props;
    let artistList = [];
    for (var i in artists) {
      let curArtist = artists[i];
      const firstname = curArtist.inputs.firstname.answer;
      const lastname = curArtist.inputs.lastname.answer;
      const website = curArtist.inputs.website.answer;
      const instagram = curArtist.inputs.instagram.answer;
      artistList.push(
        <div key={i} className={style.artist_row}>
          <div className={style.artist_item}>
            <a href={"/artist/" + curArtist.id}>
              <h3>
                {firstname} {lastname}
              </h3>
            </a>
          </div>
          <div className={style.artist_item}>
            {website ? (
              <p>
                <a href={website} target="_blank" rel="noopener noreferrer">
                  website
                </a>
              </p>
            ) : (
              <p className={classNames(style.missing)}>-</p>
            )}
          </div>
          <div className={style.artist_item}>
            {instagram ? (
              <p>
                <a href={instagram} target="_blank" rel="noopener noreferrer">
                  instagram
                </a>
              </p>
            ) : (
              <p className={classNames(style.missing)}>-</p>
            )}
          </div>
          <div className={style.artist_item}>
            <p>
              <a href={"/artist/" + curArtist.id}>read interview ></a>
            </p>
          </div>
          <hr />
        </div>
      );
    }

    return (
      <Fragment>
        <div className={classNames(style.container_s, style.all_artists)}>
          {artistList}
        </div>
        <Footer />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  let artists = state.firestore.ordered.artists;
  if (artists) {
    artists.sort(function(a, b) {
      return new Date(b.published_date) - new Date(a.published_date);
    });
    artists = artists.filter(function(artist) {
      return artist.status === "published";
    });
  }
  return {
    artists: artists
  };
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "artists" }])
)(AllArtists);
