const initState = {
  authError: null
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case "SIGNIN_ERROR":
      console.log("Sign in failed");
      return {
        ...state,
        authError: "Sign in failed"
      };
    case "SIGNIN_SUCCESS":
      console.log("Sign in succeeded");
      return {
        ...state,
        authError: null
      };
    case "SIGNOUT_SUCCESS":
      console.log("Sign out success");
      return state;
    default:
      return state;
  }
}
