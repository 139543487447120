import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import { connect } from "react-redux";
import TextInput from "../../../application-form/text-input/text-input.js";
import { createQuestion } from "../../../../store/actions/question-actions.js";

class CreateQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      subtext: "",
      limit: ""
    };
  }

  handleBlur = (index, e) => {
    // this.updates(index, e.target.value, e.target.type);
  };

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.question.length > 0) {
      this.props.createQuestion(this.state);
      this.setState({ question: "", subtext: "", limit: "" });
    } else {
    }
  };

  handleOnMouseEnter = e => {
    // this.props.setAllValids();
    // this.props.updateApplication();
  };

  render() {
    const newQueInputs = {
      question: {
        key: "question",
        placeholder: "Add a new question..."
      },
      subtext: {
        key: "subtext",
        placeholder: "Subtext"
      },
      limit: {
        key: "limit",
        max: "4",
        placeholder: "Limit"
      }
    };
    const newQueGroup = [];
    for (var curInput in newQueInputs) {
      let input = newQueInputs[curInput];
      let key = input.key;

      newQueGroup.push(
        <TextInput
          key={key}
          inputs={input}
          value={this.state[key]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
      );
    }

    return (
      <form
        onSubmit={this.handleSubmit}
        className={classNames(style.container_s, style.createContainer)}
      >
        {newQueGroup[0]}
        <div className={style.options}>
          {newQueGroup[1]}
          {newQueGroup[2]}
          <button
            className={classNames("theme_button", {
              //   [style.disabled]: !thisForm.isValid
            })}
            onMouseEnter={this.handleOnMouseEnter}
            type="submit"
          >
            Create
          </button>
        </div>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createQuestion: question => dispatch(createQuestion(question))
  };
}

export default connect(
  null,
  mapDispatchToProps
)(CreateQuestion);
