import React from "react";
import { Link } from "react-router-dom";
import style from "./style.module.scss";
import noImage from "../../../images/image_not_found.jpg";

export default class PostBanner extends React.Component {
  state = {
    image_1: "",
    image_2: "",
    image_3: "",
    hoverImage: ""
  };

  handleOnMouseEnter = e => {
    if (e.target.id === "1") {
      this.setState({ hoverImage: this.state.image_1 });
    } else if (e.target.id === "2") {
      this.setState({ hoverImage: this.state.image_2 });
    } else if (e.target.id === "3") {
      this.setState({ hoverImage: this.state.image_3 });
    }
  };

  handleOnMouseLeave = e => {
    if (this.props.defaultImage) {
      const x = this.props.defaultImage;
      this.setState({ hoverImage: this.state[`image_${x + 1}`] });
    } else {
      this.setState({ hoverImage: this.state.image_1 });
    }
  };

  setDefaultImages() {
    const { artist } = this.props;
    const images = artist.imageLoader.images;
    for (var i = 0; i < 3; i++) {
      const fileUrl =
        images[i].fileUrl === undefined ? noImage : images[i].fileUrl;
      if (i === 0) {
        this.setState({
          [`image_${i + 1}`]: fileUrl,
          hoverImage: fileUrl
        });
      } else {
        this.setState({
          [`image_${i + 1}`]: fileUrl
        });
      }
    }
    const { defaultImage } = this.props;
    if (defaultImage) {
      const fileUrl =
        images[defaultImage].fileUrl === undefined
          ? noImage
          : images[defaultImage].fileUrl;
      this.setState({
        hoverImage: fileUrl
      });
    }
  }

  componentWillMount() {
    // set new hoverImage
    this.setDefaultImages();
  }

  render() {
    const { artist } = this.props;
    const firstname = artist.inputs.firstname.answer;
    const lastname = artist.inputs.lastname.answer;

    let reloadImages = false;
    for (var i = 0; i < 3; i++) {
      const stateImage = this.state[`image_${i + 1}`];
      const firebaseImage = artist.imageLoader.images[i].fileUrl;
      if (stateImage !== firebaseImage) {
        reloadImages = true;
      }
    }
    if (reloadImages) {
      // set new default images
      this.setDefaultImages();
    }

    var image = this.state.hoverImage
      ? "url(" + this.state.hoverImage + ")"
      : null;
    var divStyle = {
      backgroundImage: image
    };
    return (
      <Link to={"/artist/" + artist.id} className={style.link}>
        <div className={style.post_banner}>
          <div className={style.post_banner_img} style={divStyle}>
            <div
              className={style.img_hover_selectors}
              onMouseLeave={this.handleOnMouseLeave}
            >
              <div
                className={style.selector}
                onMouseEnter={this.handleOnMouseEnter}
                id="1"
              />
              <div
                className={style.selector}
                onMouseEnter={this.handleOnMouseEnter}
                id="2"
              />
              <div
                className={style.selector}
                onMouseEnter={this.handleOnMouseEnter}
                id="3"
              />
            </div>
          </div>
          <div className={style.post_banner_text}>
            <h1 className={style.name}>
              {firstname} {lastname}
            </h1>
            <span className={style.article_link}>read interview ></span>
          </div>
        </div>
      </Link>
    );
  }
}
