import Firebase from "firebase/app";

export const submitQuestion = question =>
  Firebase.firestore()
    .collection("questions")
    .add(question);

export const removeQuestion = questionId =>
  Firebase.firestore()
    .collection("questions")
    .doc(questionId)
    .delete();

// needs to be an update

export const getAllQuestions = () =>
  Firebase.firestore()
    .collection("questions")
    .get()
    .then(snapshot => {
      const questions = {};
      snapshot.forEach(doc => {
        questions[doc.id] = doc.data();
      });
      return questions;
    });

export const getLockedQuestions = () =>
  Firebase.firestore()
    .collection("questions")
    .where("status", "==", "locked")
    .orderBy("order")
    .get()
    .then(snapshot => {
      const questions = {};
      snapshot.forEach(doc => {
        questions[doc.id] = doc.data();
      });
      return questions;
    });

export const getRandomQuestions = () =>
  Firebase.firestore()
    .collection("questions")
    .where("status", "==", "random")
    .get()
    .then(snapshot => {
      const questions = {};
      snapshot.forEach(doc => {
        questions[doc.id] = doc.data();
      });
      return questions;
    });

export const getEndQuestions = () =>
  Firebase.firestore()
    .collection("questions")
    .where("status", "==", "end")
    .orderBy("order")
    .get()
    .then(snapshot => {
      const questions = {};
      snapshot.forEach(doc => {
        questions[doc.id] = doc.data();
      });
      return questions;
    });
