import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import "../../styles/global.css";
import { injectStripe } from "react-stripe-elements";
import { push } from "connected-react-router";

import PaymentWrapper from "./payment.wrapper";
import FormIntro from "./form-intro/form-intro.js";
import TextInput from "./text-input/text-input.js";
import ImageUploader from "./image-uploader/image-uploader.js";
import QuestionInput from "./question-input/question-input.js";
import CheckoutForm from "../checkout-form/checkout-form.js";
import SubmittingView from "./submitting-view/submitting-view.js";
import Footer from "../layout/footer/footer.js";
import {
  resetForm,
  setComplete,
  submitForm,
  updateForm,
  fetchApplication,
  setAllValids,
  initializeForm,
  uploadImages,
  uploadCurImage,
  updateApplication,
  updateInput,
  updateQuestion,
  updateImageLoader,
  updateImages
} from "../../store/actions/application-actions.js";
import { connect } from "react-redux";
import {
  applicationFormSelector,
  applicationSelector
} from "../../store/selectors/base.selectors";
import { isFormValid } from "../../store/selectors/application.selectors.js";
import {
  cardError,
  submitCharge
} from "../../store/actions/checkout-actions.js";
import { addUse } from "../../store/actions/submission-code-actions.js";

class ApplicationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
      // checkValid: false,
      // paymentSuccess: false,
      images: {},
      isSubmitting: false
    };
    this.paymentTotal = 2;
    this.paymentCode = "";
    this.testing_code = false;
  }

  handleOnMouseEnter = e => {
    if (this.testing_code) {
      console.log("application-form: handleOnMouseEnter()");
    } ////////////////
    this.props.setAllValids();
    this.props.updateApplication();
  };

  handleBlur = (index, e) => {
    if (this.testing_code) {
      console.log("application-form: handleBlur");
    } ////////////////
    this.updates(index, e.target.value, e.target.type);
  };

  handleChange = (index, e) => {
    if (this.testing_code) {
      console.log("application-form: handleChange()");
    } ////////////////
    this.updates(index, e.target.value, e.target.type);
  };

  handleCheckoutFormChange = (e, total, code) => {
    if (this.testing_code) {
      console.log("handleCheckoutFormChange");
    } ////////////////
    this.paymentTotal = total;
    this.paymentCode = code;
  };

  handleSubmit = e => {
    if (this.testing_code) {
      console.log("application-form: handleSubmit()");
    } ////////////////

    // 1. Check if valid
    // 2. Send payment and essure valid
    // 3. Upload photos to file storage
    // 4. Change photos urls in application to download links
    // 5. Add application to database
    e.preventDefault();
    this.props.setAllValids();
    this.props.updateApplication();
    const application = this.props.fetchApplication();
    const inputs = application.form.inputs;
    if (application.isValid) {
      if (this.paymentTotal > 0) {
        let tokenParams = {
          amount: this.paymentTotal * 100,
          name: `${inputs.firstname.answer} ${inputs.lastname.answer}`,
          email: `${inputs.email.answer}`
        };
        this.props.stripe
          .createToken({ tokenParams })
          .then(({ token, error }) => {
            console.log(token, error);
            if (error) {
              cardError(error.message);
              // this.setState({ isSubmitting: false });
            } else {
              // this.setState({ isSubmitting: true });
              return Promise.all([
                this.props.submitCharge(token, tokenParams)
                // this.props.uploadImages(this.state.images)
              ]);
            }
          })
          .then(() => this.props.uploadImages(this.state.images, "folder"))
          .then(() => this.props.submitForm())
          .then(() => this.props.setComplete());
        // .catch(() => cardError());
      } else {
        this.setState({ isSubmitting: true });
        this.props
          .uploadImages(this.state.images, "folder")
          .then(() => this.props.submitForm())
          .then(() => this.props.setComplete());
      }
      if (this.paymentCode !== "") {
        this.props.addUse(this.paymentCode);
      }
    } else {
    }
  };

  updates(index, answer, type) {
    if (this.testing_code) {
      console.log("application-form: updates()");
    } ////////////////
    if (type === "text") {
      this.props.updateInput(index, answer);
    } else if (type === "textarea") {
      this.props.updateQuestion(index, answer);
    }
    this.props.updateApplication();
  }

  handleSetAllImages(allImages, allImageNames) {
    if (this.testing_code) {
      console.log("application-form: handleSetAllImages()");
    } ////////////////
    this.props.updateImageLoader(allImageNames);
    this.setState({ images: allImages });
    this.props.updateApplication();
  }

  // handleSetCurImage(curImage) {
  //   // this.props.uploadCurImage(curImage);
  // }

  componentWillMount() {
    if (this.testing_code) {
      console.log("application-form: componentWillMount()");
    } ////////////////
    this.props.initializeForm();
  }

  render() {
    if (this.testing_code) {
      console.log("application-form: render()");
    } ////////////////

    const { application, form } = this.props;
    const inputs = form.inputs;
    const questions = form.questions;
    const textInputGroup = [];
    const questionInputGroup = [];

    // console.log(this.props);

    for (var curInput in inputs) {
      let input = inputs[curInput];
      let key = input.key;
      let value = "";
      if (input.key === "website") {
        value = "https://www.yoursite.com";
      } else if (input.key === "instagram") {
        value = "https://www.instagram.com/yourprofile";
      }

      textInputGroup.push(
        <TextInput
          key={key}
          inputs={input}
          checkValid={this.state.checkValid}
          onChange={e => this.handleChange(key, e)}
          onBlur={e => this.handleBlur(key, e)}
          value={value}
        />
      );
    }

    for (var curQue in questions) {
      let index = curQue;
      questionInputGroup.push(
        <QuestionInput
          question={questions[curQue]}
          key={curQue}
          id={curQue}
          checkValid={this.state.checkValid}
          onChange={e => this.handleChange(index, e)}
          onBlur={e => this.handleBlur(index, e)}
          error={questions[curQue].error}
          reason={questions[curQue].reason}
        />
      );
    }

    if (this.state.isSubmitting) {
      // if (true) {
      return <SubmittingView application={application} />;
      // return <SubmittingView />;
    } else {
      return (
        <div className={classNames(style.submission_form)}>
          <form
            onSubmit={this.handleSubmit}
            className={classNames(style.container_s)}
          >
            <FormIntro />
            <div className={classNames(style.input_group)}>
              {textInputGroup}
            </div>
            <ImageUploader
              setAllImages={(x, y) => this.handleSetAllImages(x, y)}
              // setCurImage={curImage => this.handleSetCurImage(curImage)}
              error={form.imageLoader.error}
              reason={form.imageLoader.reason}
            />
            <div className={classNames(style.input_group)}>
              {questionInputGroup}
              {/* <MultiResponseInput /> */}
            </div>
            <hr />
            <CheckoutForm
              header="Payment"
              onChange={(e, total, code) =>
                this.handleCheckoutFormChange(e, total, code)
              }
            />
            <hr />
            <div
              className={classNames(
                style.input_group,
                style.theme_button_container
              )}
            >
              <button
                className={classNames("theme_button", {
                  [style.disabled]: !application.isValid
                })}
                onMouseEnter={this.handleOnMouseEnter}
                type="submit"
              >
                submit
              </button>
            </div>
          </form>
          <Footer />
        </div>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  // console.log(ownProps);
  return {
    application: applicationSelector(state),
    form: applicationFormSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initializeForm: () => dispatch(initializeForm()),
    fetchApplication: () => dispatch(fetchApplication()),
    resetForm: () => dispatch(resetForm()),
    setComplete: () => {
      dispatch(setComplete());
      // dispatch(push("/"));
    },
    submitForm: () => dispatch(submitForm()),
    isFormValid: () => dispatch(isFormValid()),
    setAllValids: () => dispatch(setAllValids()),
    uploadImages: (allImages, folder) =>
      dispatch(uploadImages(allImages, folder)),
    uploadCurImage: curImage => dispatch(uploadCurImage(curImage)),
    updateApplication: () => dispatch(updateApplication()),
    updateForm: update => dispatch(updateForm(update)),
    updateQuestion: (index, answer) => dispatch(updateQuestion(index, answer)),
    updateInput: (index, answer) => dispatch(updateInput(index, answer)),
    updateImageLoader: (allImages, allImageNames) =>
      dispatch(updateImageLoader(allImages, allImageNames)),
    updateImages: allImages => dispatch(updateImages(allImages)),
    addUse: code => dispatch(addUse(code)),
    submitCharge: (token, info) => dispatch(submitCharge(token, info))
  };
}

export default PaymentWrapper(
  injectStripe(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ApplicationForm)
  )
);
