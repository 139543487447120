const defaultAdmin = {
  applications: []
};

export default function adminReducer(state = defaultAdmin, action) {
  switch (action.type) {
    default:
      return state;
  }
}
