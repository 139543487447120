import {
  isFetchingAllQuestionsSelector,
  isFetchingLockedQuestionsSelector,
  isFetchingRandomQuestionsSelector,
  isFetchingEndQuestionsSelector
} from "../selectors/base.selectors";

import {
  getAllQuestions,
  getLockedQuestions,
  getRandomQuestions,
  getEndQuestions
  // submitQuestion,
  // removeQuestion
} from "../api/question-api.js";

const ACTION_PREFIX = "@@question";
export const IS_FETCHING_ALL = `${ACTION_PREFIX}/IS_FETCHING_ALL`;
export const IS_FETCHING_LOCKED = `${ACTION_PREFIX}/IS_FETCHING_LOCKED`;
export const IS_FETCHING_RANDOM = `${ACTION_PREFIX}/IS_FETCHING_RANDOM`;
export const IS_FETCHING_END = `${ACTION_PREFIX}/IS_FETCHING_END`;
export const FETCHED_ALL = `${ACTION_PREFIX}/FETCHED_ALL`;
export const FETCHED_LOCKED = `${ACTION_PREFIX}/FETCHED_LOCKED`;
export const FETCHED_RANDOM = `${ACTION_PREFIX}/FETCHED_RANDOM`;
export const FETCHED_END = `${ACTION_PREFIX}/FETCHED_END`;

export const createQuestion = question => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  firestore
    .collection("questions")
    .add({
      ...question,
      status: "random",
      order: "",
      creation_date: new Date().toLocaleString()
    })
    .then(() => {
      dispatch({ type: "CREATE_QUESTION", question });
    })
    .catch(err => {
      dispatch({ type: "CREATE_QUESTION_ERROR", err });
    });
};

export const deleteQuestion = question => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  // console.log(question);
  const firestore = getFirestore();
  firestore
    .collection("questions")
    .doc(question.id)
    .delete()
    .then(() => {
      dispatch({ type: "DELETE_QUESTION", question });
    })
    .catch(err => {
      dispatch({ type: "DELETE_QUESTION_ERROR", err });
    });
};

export const fetchAllQuestions = () => (dispatch, getState) => {
  const state = getState();
  if (isFetchingAllQuestionsSelector(state)) {
    return null;
  }
  dispatch({ type: IS_FETCHING_ALL });
  return getAllQuestions().then(questions =>
    dispatch({ type: FETCHED_ALL, questions })
  );
};

export const fetchLockedQuestions = () => (dispatch, getState) => {
  const state = getState();
  if (isFetchingLockedQuestionsSelector(state)) {
    return null;
  }
  dispatch({ type: IS_FETCHING_LOCKED });
  return getLockedQuestions().then(questions =>
    dispatch({ type: FETCHED_LOCKED, questions })
  );
};

export const fetchRandomQuestions = () => (dispatch, getState) => {
  const state = getState();
  if (isFetchingRandomQuestionsSelector(state)) {
    return null;
  }
  dispatch({ type: IS_FETCHING_RANDOM });
  return getRandomQuestions().then(questions =>
    dispatch({ type: FETCHED_RANDOM, questions })
  );
};

export const fetchEndQuestions = () => (dispatch, getState) => {
  const state = getState();
  if (isFetchingEndQuestionsSelector(state)) {
    return null;
  }
  dispatch({ type: IS_FETCHING_END });
  return getEndQuestions().then(questions =>
    dispatch({ type: FETCHED_END, questions })
  );
};

export const isQuestionValid = () => ({});
