/* action creator */
export function createArtist(artist) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("artists")
      .add({
        ...artist,
        status: "posted",
        posted_date: new Date().toLocaleString()
      })
      .then(() => {
        dispatch({ type: "CREATE_ARTIST", artist });
      })
      .catch(err => {
        dispatch({ type: "CREATE_ARTIST_ERROR", err });
      });
  };
}

export function loadImage(curImage, folder) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // console.log(curImage);
    if (!folder) {
      folder = `application_images/${curImage.fileName}`;
      // folder = "application_images_testing";
    } else {
      folder = `${folder}/${curImage.fileName}`;
    }
    // console.log(location);
    const firebase = getFirebase();
    firebase
      .storage()
      .ref(folder)
      .getDownloadURL()
      .then(url => {
        curImage.fileUrl = url;
        // console.log(curImage);
        dispatch({ type: "LOAD_IMAGE", curImage });
      })
      .catch(err => {});
  };
}

// export const fetchImages = () => (dispatch, getState) => {
//   const state = getState();
//   return state.artists.images;
// };

// export function updateArtists(artists, collection) {
//   return (dispatch, getState, { getFirebase, getFirestore }) => {
//     console.log("artist-actions.js => updateArtists()");
//     if (artists) {
//       const firebase = getFirebase();
//       // console.log(collection);
//       for (const curArtist of artists) {
//         // console.log(curArtist);
//         for (const curImage of curArtist.imageLoader.images) {
//           // console.log(curImage);
//           if (curImage.fileUrl === undefined) {
//             firebase
//               .storage()
//               .ref(`application_images/${curImage.fileName}`)
//               .getDownloadURL()
//               .then(curUrl => {
//                 const tempNewImage = {
//                   fileName: curImage.fileName,
//                   fileUrl: curUrl
//                 };
//                 console.log(tempNewImage);
//                 // dispatch(saveImageUrl(curImage, collection, curArtist.id));
//               });
//           } else {
//             console.log("curImage has fileUrl saved");
//           }
//         }
//       }
//     }
//   };
// }

export function storeImageUrl(imageArray, collection, id) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log("artist-actions.js => storeImageUrl()");
    const firestore = getFirestore();
    // const firebase = getFirebase();
    firestore
      .collection(collection)
      .doc(id)
      .update({
        imageLoader: {
          images: {
            ...imageArray
          }
        }
      })
      .then(() => {
        console.log("bleep blorp");
      })
      .catch(err => {});
  };
}
