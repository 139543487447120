import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false
      // hasText: false
    };
    this.hasText = this.props.hasDefaultValue ? true : false;
  }

  handleFocus = e => {
    // console.log("text-input: handleFocus(e)");
    this.setState({ isFocused: true });
    if (e.target.value.length > 0) {
      this.hasText = true;
    } else {
      this.hasText = false;
    }
  };

  handleBlur = e => {
    // console.log("text-input: handleBlur(e)");
    this.setState({ isFocused: false });
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
    if (e.target.value.length > 0) {
      this.hasText = true;
    } else {
      this.hasText = false;
    }
  };

  handleChange = e => {
    // console.log("text-input: onChange(e)");
    if (e.target.value.length > 0) {
      this.hasText = true;
    } else {
      this.hasText = false;
    }
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  render() {
    // console.log("text-input: render()");
    if (this.props.defaultValue) {
      this.hasText = true;
    }

    let inputs = [];
    if (
      this.props.inputs instanceof Object &&
      !(this.props.inputs instanceof Array)
    ) {
      inputs.push(this.props.inputs);
    } else {
      inputs = this.props.inputs;
    }

    let isFocused = this.state.isFocused;
    let error = null;
    const inputsGroup = [];

    for (var i in inputs) {
      let input = inputs[i];
      error = input.error;
      let reason = input.reason;
      // console.log(input.hasText);
      // console.log(this.hasText);
      let hasText = input.hasText ? input.hasText : this.hasText;

      inputsGroup.push(
        <div key={input.key} className={classNames(style.input, style.left)}>
          <span
            className={classNames({
              [style.active]: isFocused || hasText
            })}
          >
            {input.placeholder}
            {error ? ` - ${reason}` : null}
          </span>
          <input
            id={input.key}
            type={input.type ? input.type : "text"}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            maxLength={input.max ? input.max : 200}
            placeholder={
              this.state.isFocused && this.props.value ? this.props.value : ""
            }
            defaultValue={this.props.defaultValue}
          />
        </div>
      );
    }

    return (
      <div
        className={classNames(
          style.text_input,
          {
            [style.focus]: isFocused
          },
          {
            [style.error]: error
          }
        )}
      >
        {inputsGroup}
      </div>
    );
  }
}

export default TextInput;
