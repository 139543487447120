import { isFetchingInputsSelector } from "../selectors/base.selectors";

import { getAllInputs } from "../api/input-field-api.js";

const ACTION_PREFIX = "@@input_field";
export const IS_FETCHING_ALL = `${ACTION_PREFIX}/IS_FETCHING_ALL`;
export const FETCHED_ALL = `${ACTION_PREFIX}/FETCHED_ALL`;

export const fetchAllInputs = () => (dispatch, getState) => {
  const state = getState();
  if (isFetchingInputsSelector(state)) {
    return null;
  }
  dispatch({ type: IS_FETCHING_ALL });
  return getAllInputs().then(inputs => dispatch({ type: FETCHED_ALL, inputs }));
};
