import {
  RESET_FORM,
  FORM_SUBMISSION_SUCCESS,
  RESET_SUCCESS,
  UPDATED_FORM,
  UPDATED_INPUT,
  UPDATED_QUESTION,
  UPDATED_IMAGEUPLOADER,
  UPDATED_APPLICATION,
  INITIALIZED_FORM,
  SET_COMPLETE,
  ADD_IMAGE,
  ADD_IMAGE_ERROR,
  IS_SAVING_IMAGE
} from "../actions/application-actions.js";

const defaultForm = {
  imageLoader: { images: [] },
  questions: {},
  inputs: {}
};

const defaultState = {
  form: defaultForm,
  isLoaded: false,
  isSuccess: false,
  isValid: false,
  isComplete: false
};

export default function applicationReducer(state = defaultState, action) {
  switch (action.type) {
    case INITIALIZED_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          questions: {
            ...action.questions
          },
          inputs: {
            ...action.inputs
          }
        }
      };
    // return state;
    case UPDATED_APPLICATION:
      return {
        ...state,
        ...action.update
      };
    case UPDATED_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          imageLoader: {
            ...state.form.imageLoader,
            ...(action.update.imageLoader || {})
          },
          questions: {
            ...state.form.questions,
            ...(action.update.questions || {})
          },
          inputs: {
            ...state.form.inputs,
            ...(action.update.inputs || {})
          }
        }
      };
    case UPDATED_INPUT:
      return {
        ...state,
        form: {
          ...state.form,
          inputs: {
            ...state.form.inputs,
            ...(action.update.inputs || {})
          }
        }
      };
    case UPDATED_QUESTION:
      return {
        ...state,
        form: {
          ...state.form,
          questions: {
            ...state.form.questions,
            ...(action.update.questions || {})
          }
        }
      };
    case UPDATED_IMAGEUPLOADER:
      return {
        ...state,
        form: {
          ...state.form,
          imageLoader: {
            ...state.form.imageLoader,
            ...(action.update.imageLoader || {})
          }
        }
      };
    case RESET_FORM:
      return {
        ...state,
        form: { ...defaultForm }
      };
    case FORM_SUBMISSION_SUCCESS:
      return { ...state, form: defaultForm, isSuccess: true };
    case RESET_SUCCESS:
      return { ...state, isSuccess: false };
    case SET_COMPLETE:
      return { ...state, isComplete: true };
    case IS_SAVING_IMAGE:
      return {
        ...state,
        form: {
          ...state.form,
          imageLoader: {
            ...state.form.imageLoader,
            images: [
              ...state.form.imageLoader.images
              // [action.image.order]: {
              //   ...state.form.imageLoader.images[action.image.order],
              //   isSaving: true
              // }
            ]
          }
        }
      };
    case ADD_IMAGE:
      return {
        ...state,
        form: {
          ...state.form,
          imageLoader: {
            ...state.form.imageLoader,
            images: [
              ...state.form.imageLoader.images
              // [action.image.order]: {
              //   // ...state.form.imageLoader.images[action.image.order],
              //   isSaving: action.image.isSaving,
              //   saved: action.image.saved
              // }
            ]
          }
        }
      };
    case ADD_IMAGE_ERROR:
      return {
        ...state
      };
    ////////////////////////////////////////////
    // case "CREATE_APPLICATION":
    //   console.log("create application", action.application);
    //   state = { ...state };
    //   return state;
    // case "CREATE_APPLICATION_ERROR":
    //   console.log("create application error", action.err);
    //   state = { ...state };
    //   return state;
    // case "SET_ANSWER":
    //   // console.log("application-reducer: SET_ANSWER", action);
    //   state = { ...state };
    //   return state;
    default:
      return state;
  }
}
