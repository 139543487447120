import React, { Fragment } from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import noImage from "../../../images/image_not_found.jpg";
// import noImage from "images/testimage.jpg";

import { push } from "connected-react-router";
import { connect } from "react-redux";

import ImageSlider from "../../artists/image-slider/image-slider.js";
import { Globe, Instagram } from "react-feather";

class Featured extends React.Component {
  handleClick = e => {
    this.props.goToArtist(this.props.artist.id);
  };

  render() {
    const { artist } = this.props;
    if (artist) {
      const questions = artist.questions;
      const inputs = artist.inputs;
      const firstname = inputs.firstname.answer;
      const lastname = inputs.lastname.answer;
      const name = `${firstname} ${lastname}`;
      const website = inputs.website.answer;
      const instagram = inputs.instagram.answer;

      const images = artist.imageLoader.images;
      const imgList = [];
      for (var i = 0; i < 5; i++) {
        const fileUrl =
          images[i].fileUrl === undefined ? noImage : images[i].fileUrl;
        imgList.push(<img key={i} alt={`artwork by ${name}`} src={fileUrl} />);
      }

      return (
        <Fragment>
          {/* <a href={"/artist/" + artist.id}> */}
          <div className={style.featured_bar}>
            <div className={classNames(style.featured_text)}>
              <div className={style.text_padding}>
                <span className={style.date}>
                  {new Date(artist.published_date).toLocaleDateString({
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })}
                </span>
                <h1 onClick={this.handleClick}>
                  {firstname} {lastname}
                </h1>
                <p>{questions[0].answer}</p>
                <ul onClick={this.handleClick}>
                  {!!artist.status && artist.status === "review" ? null : (
                    <li className={style.article_link}>read interview ></li>
                  )}
                </ul>
                <ul className={style.artist_links}>
                  {instagram ? (
                    <li>
                      <a
                        href={instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Instagram />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {website ? (
                    <li>
                      <a
                        href={website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Globe />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
            <div
              className={style.featured_img_slider}
              onClick={this.handleClick}
            >
              <ImageSlider
                artist={artist}
                artistID={this.props.artistID}
                numImagesToShow={5}
              />
            </div>
          </div>
          {/* </a> */}
        </Fragment>
      );
    } else {
      return <h1>Loading...</h1>;
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    goToArtist: id => {
      dispatch(push("/artist/" + id));
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(Featured);
