import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

// Initialize Firebase
var config = {
  apiKey: "AIzaSyBXnCQdbI1uKB5RDVAYX6SW_1YC5wYNXOs",
  authDomain: "mindsetarchives-9c97d.firebaseapp.com",
  databaseURL: "https://mindsetarchives-9c97d.firebaseio.com",
  projectId: "mindsetarchives-9c97d",
  storageBucket: "mindsetarchives-9c97d.appspot.com",
  messagingSenderId: "535088540137"
};

firebase.initializeApp(config);
// firebase.firestore().settings({ timestampsInSnapshots: true }); // firestore 5.8.6 - it is recommended that you remove this

export default firebase;
