import React, { Fragment } from "react";
import Featured from "./featured/featured.js";
import PostList from "./post-list/post-list.js";
import Footer from "../layout/footer/footer.js";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

// import { updateArtists } from "../../store/actions/artist-actions";

class BlogDisplay extends React.Component {
  render() {
    const { artists } = this.props;
    // this.props.updateArtists(artists, "artists");
    const featArtist = artists ? artists[0] : null;
    const otherArtists = artists ? artists.slice(1) : null;
    return (
      <Fragment>
        <Featured artist={featArtist} artistID={null} />
        <PostList artists={otherArtists} detail={"latest"} />
        <Footer />
      </Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let artists = state.firestore.ordered.artists;
  if (artists) {
    artists.sort(function(a, b) {
      return new Date(b.published_date) - new Date(a.published_date);
    });
    artists = artists.filter(function(artist) {
      return artist.status === "published";
    });
  }
  return {
    artists: artists
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // updateArtists: (artists, collection) =>
    //   dispatch(updateArtists(artists, collection))
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "artists" }])
)(BlogDisplay);
