import { createSelector } from "reselect";
import { inputItemsSelector } from "./base.selectors";

export const selectNewFormInputs = createSelector(
  [inputItemsSelector],
  inputItems => {
    const inputsLength = Object.keys(inputItems).length;
    if (inputsLength > 0) {
      const newFormInputs = {};
      let index = "";

      for (const key in inputItems) {
        index = inputItems[key].key;
        newFormInputs[index] = Object.assign({}, inputItems[key]);
        newFormInputs[index].id = key;
        newFormInputs[index].answer = "";
        newFormInputs[index].valid = null;
        newFormInputs[index].reason = null;
        newFormInputs[index].hasText = null;
      }

      return newFormInputs;
    }
  }
);
