import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";

export default class ArtistIntro extends React.Component {
  render() {
    const { artist } = this.props;
    const inputs = artist.inputs;
    // const id = "TODO";
    const firstname = inputs.firstname.answer;
    const lastname = inputs.lastname.answer;
    const birthyear = inputs.birthyear.answer;
    const age = new Date().getFullYear() - birthyear;
    const email = inputs.email.answer;
    const website = inputs.website.answer;
    const instagram = inputs.instagram.answer;
    const secret = this.props.secret;

    const date = artist.published_date
      ? artist.published_date
      : artist.submission_date;
    return (
      <div className={style.artist_intro}>
        <h1>
          {firstname} {lastname}
        </h1>
        <ul>
          {website ? (
            <li className={style.link}>
              <a href={website} target="_blank" rel="noopener noreferrer">
                website
              </a>
            </li>
          ) : secret ? (
            <li className={classNames(style.missing)}>[no website]</li>
          ) : null}
          {instagram ? (
            <li className={style.link}>
              <a href={instagram} target="_blank" rel="noopener noreferrer">
                instagram
              </a>
            </li>
          ) : secret ? (
            <li className={classNames(style.missing)}>[no instagram]</li>
          ) : null}
        </ul>
        {secret ? (
          <ul className={style.personal_info}>
            {/* <li>application id: {id}, </li> */}
            <li>
              born: {birthyear} (age: {age})
            </li>
            <li>email: {email}</li>
          </ul>
        ) : null}
        <ul>
          {/* <li className={style.tags}>
            <a href="/">TODO:</a>, <a href="/">tags</a>
          </li> */}
          {secret ? (
            <li className={style.date}>{date}</li>
          ) : (
            <li className={style.date}>
              {new Date(date).toLocaleDateString({
                year: "numeric",
                month: "long",
                day: "numeric"
              })}
            </li>
          )}
        </ul>
      </div>
    );
  }
}
