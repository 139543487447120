import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";

// import AdminLinks from "./admin-links.js";
// import PublicLinks from "./public-links.js";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";
import { signOut } from "../../../store/actions/auth-actions.js";
import { LogOut, Instagram, Mail, Menu } from "react-feather";

class Navbar extends React.Component {
  // MAKE NAVBAR HEIGHT SMALLER ON SCROLL (example code from stack exchange)
  constructor(props) {
    super(props);
    this.state = {
      smaller: false
    };
    this.testing_code = false;
    this.lastY = null;
  }

  componentDidMount() {
    window.addEventListener("scroll", this.resizeHeaderOnScroll.bind(this));
  }

  resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 20;

    const curY = window.pageYOffset;

    if (curY > this.lastY) {
      this.setState({ smaller: true });
      // console.log("true");
    } else if (distanceY > shrinkOn) {
      this.setState({ smaller: false });
    } else {
      this.setState({ smaller: false });
      // console.log("false");
    }

    this.lastY = curY;
  }

  render() {
    // console.log(this.state);
    let hasAppsInInbox = this.props.hasAppsInInbox;
    //////////

    const auth = this.props.auth;
    // const links = auth.uid ? <AdminLinks /> : <PublicLinks />;
    return (
      <nav
        className={classNames(style.navbar)}
        // className={classNames(style.navbar, {
        //   [style.smaller]: this.state.smaller
        // })}
      >
        <div
          className={classNames(style.float, {
            [style.smaller]: this.state.smaller
          })}
        >
          <div className={style.left}>
            <Link to="/">archive-ism</Link>
            {auth.uid ? (
              <section>
                <a href="/admin/inbox" className={style.admin_label}>
                  admin
                  <div
                    className={classNames(style.notification_icon, {
                      [style.active]: hasAppsInInbox
                    })}
                  />
                </a>
                <button
                  className={style.icon_button}
                  onClick={this.props.signOut}
                >
                  <LogOut />
                </button>
              </section>
            ) : null}
          </div>

          <div className={style.middle}>
            <a
              href="https://www.instagram.com/archive_ism/"
              className={style.social_logo}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Instagram />
            </a>
            <a
              href="mailto:info@archive-ism.com"
              className={style.social_logo}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Mail />
            </a>
          </div>
          <div className={style.right}>
            <Link to="/">latest</Link>
            <Link to="/submit">submit</Link>
          </div>
          <div
            className={style.menu_icon}
            onClick={this.props.handleMenuDrawerOpen}
          >
            <Menu />
          </div>
        </div>

        {this.props.children}
      </nav>
    );
  }
}

function mapStateToProps(state) {
  const apps = state.firestore.data.applications;
  var hasAppsInInbox = false;
  if (apps) {
    var inboxApps = Object.values(apps).filter(function(app) {
      return !!app ? app.status === "inbox" : null;
    });
    if (inboxApps.length > 0) {
      hasAppsInInbox = true;
    } else {
      hasAppsInInbox = false;
    }
  }
  return {
    auth: state.firebase.auth,
    hasAppsInInbox: hasAppsInInbox
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: () => dispatch(signOut())
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "applications", orderBy: "submission_date" }])
)(Navbar);
