import React from "react";
import style from "./style.module.scss";
import noImage from "../../../images/image_not_found.jpg";
import { setAllUrls } from "../../../store/api/image-uploader-api";
import { loadImage } from "../../../store/actions/artist-actions.js";
import { imagesSelector } from "../../../store/selectors/base.selectors";
// import noImage from "images/testimage.jpg";

import { connect } from "react-redux";

class ImageSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedUrls: []
    };
    this.imgList = [];
    this.firestoreArray = [];
  }

  componentDidMount() {
    if (this.props.artist) {
      const images = this.props.artist.imageLoader.images;
      const collection =
        this.props.artist.status === "published" ? "artists" : "applications";
      if (this.props.artistID) {
        setAllUrls(images, collection, this.props.artistID);
      }
      // Promise.all(images.map(image => getImageUrl(image.fileName))).then(
      //   urls => {
      //     this.setState({ loadedUrls: urls });
      //   }
      // );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.artist !== prevProps.artist) {
      document.getElementById("slider").scrollLeft = 0;
      const images = this.props.artist.imageLoader.images;
      const collection =
        this.props.artist.status === "published" ? "artists" : "applications";
      // console.log(this.props.artistID);
      if (this.props.artistID) {
        setAllUrls(images, collection, this.props.artistID);
      }
    }
  }

  render() {
    const { artist } = this.props;
    // console.log(artist);
    const name = `${artist.inputs.firstname.answer} ${
      artist.inputs.lastname.answer
    }`;

    const images = artist.imageLoader.images;
    // console.log(images);
    // if (this.props.numImagesToShow != null) {
    //   images.length = this.props.numImagesToShow;
    // }
    this.imgList = [];
    this.firestoreArray = [];
    // console.log(images);

    const length = this.props.numImagesToShow
      ? this.props.numImagesToShow
      : images.length;
    for (var i = 0; i < length; i++) {
      const fileUrl = images[i] === undefined ? noImage : images[i].fileUrl;
      this.imgList.push(
        <img key={i} alt={`artwork by ${name}`} src={fileUrl} />
      );
    }

    // console.log("-----end-----");

    return (
      <div className={style.image_slider} id="slider">
        <div className={style.slider}>{this.imgList}</div>
      </div>
    );
  }
}

// function mapStateToProps(state, ownProps) {
//   const id = ownProps.match.params.id;
//   const artists = state.firestore.data.artists;
//   const artist = artists ? artists[id] : null;
//   return {
//     artistID: id,
//     artists: state.firestore.ordered.artists,
//     artist: artist
//   };
// }

function mapStateToProps(state, ownProps) {
  return {
    images: imagesSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadImage: (curImage, folder) => dispatch(loadImage(curImage, folder))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageSlider);
