//   const ACTION_PREFIX = "@@submission-code";
//   export const IS_FETCHING_ALL = `${ACTION_PREFIX}/IS_FETCHING_ALL`;

import { submissionCodesSelector } from "../selectors/base.selectors.js";

// const ACTION_PREFIX = "@@submission_code";

export const createCode = code => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  firestore
    .collection("submission_codes")
    .add({
      ...code,
      active: true,
      creation_date: new Date().toLocaleString(),
      uses: 0
    })
    .then(() => {
      console.log("created submission code");
      dispatch({ type: "CREATE_SUBMISSION_CODE", code });
    })
    .catch(err => {
      dispatch({ type: "CREATE_SUBMISSION_CODE_ERROR", err });
    });
};

export const deleteCode = code_id => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  firestore
    .collection("submission_codes")
    .doc(code_id)
    .delete()
    .then(() => {
      dispatch({ type: "DELETE_CODE", code_id });
    })
    .catch(err => {
      dispatch({ type: "DELETE_CODE_ERROR", err });
    });
};

export const updateCode = codeObject => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  firestore
    .collection("submission_codes")
    .doc(codeObject.id)
    .update({
      ...codeObject
    })
    .then(() => {
      dispatch({ type: "CODE_UPDATED", codeObject });
    })
    .catch(err => {
      dispatch({ type: "CODE_UPDATED_ERROR", err });
    });
};

export const addUse = code => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const state = getState();
  const codes = submissionCodesSelector(state);
  var foundObject = codes.filter(obj => obj.code === code);

  if (foundObject.length > 0) {
    foundObject = foundObject[0];
    foundObject.uses = foundObject.uses + 1;
    dispatch(updateCode(foundObject));
  }
};

export const checkCode = code => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const state = getState();
  const codes = submissionCodesSelector(state);
  var foundObject = codes.filter(obj => obj.code === code);
  if (foundObject.length > 0) {
    foundObject = foundObject[0];
    const valid = isValid(foundObject);
    foundObject.active = valid;
    dispatch(updateCode(foundObject));
    return valid;
  }
};

export const isValid = code => {
  if (code.active) {
    // check max uses
    if (code.max === "") {
      // no max specified
    } else if (code.uses === Number(code.max)) {
      // reached max uses
      return false;
    }
    // check duration limit
    if (code.duration === "") {
      // no duration limit specified
    } else {
      const daysActive = Math.round(
        (new Date() - new Date(code.creation_date)) / (1000 * 60 * 60) / 24
      );
      if (daysActive > Number(code.duration)) {
        // reached duration limit, no time left
        return false;
      } else {
        // has time left, is valid
        return true;
      }
    }
    // is valid
    return true;
  }
  // default
  return false;
};
