import React from "react";
import style from "./style.module.scss";
import { Edit, Trash2, Save } from "react-feather";
import EditQuestion from "../edit-question/edit-question.js";
import { connect } from "react-redux";
import { deleteQuestion } from "../../../../store/actions/question-actions.js";

class QuestionElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false
    };
    this.testing_code = true;
  }

  handleEdit = e => {
    console.log("handleEdit");
    this.setState({
      edit: true
    });
  };

  handleSave = e => {
    console.log("handleSave");
    this.setState({
      edit: false
    });
  };

  handleDelete = e => {
    if (this.testing_code) {
      console.log("question-element: handleDelete()");
      console.log(this.props.question);
    } ////////////////
    e.preventDefault();

    if (this.areYouSure("delete this question")) {
      this.props.deleteQuestion(this.props.question);
    }
  };

  areYouSure(reason) {
    return window.confirm("Are you sure you want to " + reason + "?");
  }

  render() {
    return (
      <div className={style.question_container}>
        {this.state.edit ? (
          <EditQuestion question={this.props.question} />
        ) : (
          <div className={style.question}>
            <p>{this.props.question.question}</p>
            {this.props.question.subtext ? (
              <p className={style.subtext}>{this.props.question.subtext}</p>
            ) : null}
          </div>
        )}

        {this.state.edit ? (
          <Save onClick={this.handleSave} className={style.save} />
        ) : (
          <Edit onClick={this.handleEdit} />
        )}
        <Trash2 onClick={this.handleDelete} />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deleteQuestion: question => dispatch(deleteQuestion(question))
  };
}

export default connect(
  null,
  mapDispatchToProps
)(QuestionElement);
