const initState = {
  artist: {},
  images: []
};
// export default function applicationReducer(state = defaultState, action) {
//   switch (action.type) {
//     case INITIALIZED_FORM:

export default function artistReducer(state = initState, action) {
  switch (action.type) {
    case "CREATE_ARTIST":
      console.log("create artist", action.artist);
      return state;
    case "CREATE_ARTIST_ERROR":
      console.log("create artist error", action.err);
      return state;
    case "LOAD_IMAGE":
      // console.log("load image", action.curImage);
      const image = action.curImage;
      return {
        ...state,
        images: [...state.images, image]
      };
    default:
      return state;
  }
}
