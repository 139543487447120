import React, { Component } from "react";
import { StripeProvider, Elements } from "react-stripe-elements";

const PaymentWrapper = Element =>
  class WrappedComponent extends Component {
    constructor() {
      super();
      this.state = { stripe: null };
    }

    componentDidMount() {
      console.log(window.Stripe);
      if (window.Stripe) {
        this.setState({
          stripe: window.Stripe("pk_live_NA86Nqp3nq1z5uRAdHrEGPdX")
        });
      } else {
        document.querySelector("#stripe-js").addEventListener("load", () => {
          this.setState({
            stripe: window.Stripe("pk_live_NA86Nqp3nq1z5uRAdHrEGPdX")
          });
        });
      }
    }

    render() {
      // this.state.stripe will either be null or a Stripe instance
      // depending on whether Stripe.js has loaded.
      const { stripe } = this.state;
      return (
        <StripeProvider stripe={stripe}>
          <Elements>
            <Element />
          </Elements>
        </StripeProvider>
      );
    }
  };

export default PaymentWrapper;
