import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

import CreateCode from "../submission-codes/create-code/create-code.js";
import { deleteCode } from "../../../store/actions/submission-code-actions.js";
import { Trash2 } from "react-feather";

class SubmissionCodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.testing_code = false;
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  areYouSure(reason) {
    return window.confirm("Are you sure you want to " + reason + "?");
  }

  deleteCode = code_id => e => {
    if (this.testing_code) {
      console.log("submission-codes: removeCode()");
    } ////////////////
    e.preventDefault();

    if (this.areYouSure("delete this submission code")) {
      this.props.deleteCode(code_id);
    }
  };

  render() {
    let codeList = [];
    let numCodes = this.props.codes ? this.props.codes.length : "-";
    for (var i in this.props.codes) {
      let curCode = this.props.codes[i];
      let uses = curCode.uses ? curCode.uses : 0;
      let max = curCode.max === "" ? "no max" : `${curCode.max} use max`;
      let active = curCode.active ? "Active" : "Inactive";
      let duration = curCode.duration;
      if (duration === "") {
        duration = "no end";
      } else {
        duration = Number(duration);
        const createdDate = new Date(curCode.creation_date);
        const hoursActive = (new Date() - createdDate) / (1000 * 60 * 60);
        const daysActive = Math.round(hoursActive / 24);

        // console.log(hoursActive);
        // console.log(daysActive);

        if (daysActive > duration) {
          duration = "0 time left";
        } else {
          let difference = duration - daysActive;
          if (difference === 1) {
            duration = `1 day left`;
          } else if (difference > 0) {
            duration = `${difference} days left`;
          }
        }

        // console.log(duration);
      }

      //no end
      // console.log(curCode);

      codeList.push(
        <div key={i} className={style.code_row}>
          <div className={style.code_item}>
            <h3>{curCode.code}</h3>
          </div>
          <div className={style.code_item}>
            <p className={classNames({ [style.hasUses]: curCode.uses > 0 })}>
              {uses} uses
            </p>
          </div>
          <div className={style.code_item}>
            <p>{max}</p>
          </div>
          <div className={style.code_item}>
            <p>
              {duration}
              {/* <span>30</span> days left */}
            </p>
          </div>
          <div className={style.code_item}>
            <p>{active}</p>
          </div>
          <div className={classNames(style.code_item, style.options)}>
            <Trash2 onClick={this.deleteCode(curCode.id)} />
          </div>
          <hr />
        </div>
      );
    }

    return (
      <div className={style.submission_codes}>
        <div className={classNames(style.container_s, style.container)}>
          {/* Create Code */}
          <h1 className={style.header}>Create A New Submission Code</h1>
          <p className={style.subtext}>Create a custom code or hit generate.</p>
          <CreateCode />

          {/* Exsiting Codes */}
          <h1 className={style.header}>Existing Codes ({numCodes})</h1>
          <p className={style.subtext} />
          {codeList}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let codes = state.firestore.ordered.submission_codes;
  if (codes) {
    codes.sort(function(a, b) {
      return new Date(b.creation_date) - new Date(a.creation_date);
    });
    // console.log(codes);
  }
  return {
    codes: codes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteCode: code_id => dispatch(deleteCode(code_id))
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "submission_codes" }])
)(SubmissionCodes);
