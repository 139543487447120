import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import style from "./style.module.scss";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

// function QueueCard({ application, match }) {
class QueueCard extends React.Component {
  render() {
    const { application } = this.props;
    const { match } = this.props;
    const { isFoldable } = this.props;
    const { isCollapsed } = this.props;
    const inputs = application.inputs;
    const firstname = inputs.firstname.answer;
    const lastname = inputs.lastname.answer;
    // const birthyear = inputs.birthyear.answer;
    // const website = inputs.website.answer;
    // const instagram = inputs.instagram.answer;
    const startDate = new Date(application.submission_date);
    const hours = (new Date() - startDate) / (1000 * 60 * 60);
    const days = Math.round(hours / 24);
    let timePast = "";
    if (days > 0) {
      timePast = `${days} days ago`;
    } else if (hours < 1) {
      timePast = `${Math.floor(hours * 60)} minutes ago`;
    } else {
      timePast = `${Math.floor(hours)} hours ago`;
    }

    return (
      <NavLink
        to={`${match.path}/${application.id}`}
        className={classNames(
          style.queue_card,
          { [style.isFoldable]: isFoldable },
          { [style.isCollapsed]: isCollapsed }
        )}
        activeClassName={style.active}
      >
        {isCollapsed ? (
          <h1>
            {firstname.charAt(0)}
            {lastname.charAt(0)}
          </h1>
        ) : (
          <Fragment>
            <div className={style.top}>
              <span className={style.name}>
                {firstname} {lastname}
              </span>
              <span className={style.date}>{timePast}</span>
            </div>
            <div className={style.bottom}>
              <span className={style.category}>TODO: Add functionality</span>
            </div>
          </Fragment>
        )}
      </NavLink>
    );
  }
}

export default withRouter(QueueCard);
