import Firebase from "firebase/app";

export const submitInput = input =>
  Firebase.firestore()
    .collection("input_fields")
    .add(input);

export const removeInput = inputId =>
  Firebase.firestore()
    .collection("input_fields")
    .doc(inputId)
    .delete();

// needs to be an update

export const getAllInputs = () =>
  Firebase.firestore()
    .collection("input_fields")
    .orderBy("order")
    .get()
    .then(snapshot => {
      const input_fields = {};
      snapshot.forEach(doc => {
        input_fields[doc.id] = doc.data();
      });
      return input_fields;
    });
