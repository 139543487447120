import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Edit } from "react-feather";

class QandA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkBoxHover: false,
      checkBoxSelected: false
    };
  }

  handleOnMouseEnter = e => {
    // console.log("QandA: handleOnMouseEnter(e)");
    this.setState({ checkBoxHover: true });
  };

  handleOnMouseLeave = e => {
    // console.log("QandA: handleOnMouseLeave(e)");
    this.setState({ checkBoxHover: false });
  };

  render() {
    const { info } = this.props;
    let checkBoxHover = this.state.checkBoxHover;
    let checkBoxSelected = this.state.checkBoxSelected;

    if (this.props) {
      if (this.props.display !== undefined ? this.props.display : true) {
        return (
          <div className={style.q_and_a}>
            <div
              className={classNames(style.question, {
                [style.user_select]: this.props.secret
              })}
            >
              {info.question}
            </div>
            <div
              className={classNames(
                style.answer,
                {
                  [style.missing]: !info.answer
                },
                {
                  [style.user_select]: this.props.secret
                }
              )}
            >
              {info.answer ? info.answer : "[This question was left blank.]"}
            </div>
            {this.props.editable ? (
              <div className={style.options}>
                <Edit onClick={null} />
                <label
                  onMouseEnter={this.handleOnMouseEnter}
                  onMouseLeave={this.handleOnMouseLeave}
                  className={classNames(
                    {
                      [style.hover]: checkBoxHover
                    },
                    {
                      [style.active]: checkBoxSelected
                    }
                  )}
                >
                  Do not display
                  <input type="checkbox" name="display" value="display" />
                  <span className={style.checkmark} />
                </label>
                {/* <h1>Order: {info.order}</h1> */}
              </div>
            ) : null}
          </div>
        );
      } else if (!this.props.display) {
        return null;
      } else {
        return <h1>Loading...</h1>;
      }
    }
  }
}

// export default QandA;

function mapStateToProps(state, ownProps) {
  const questions = state.firestore.ordered.questions;
  if (questions && ownProps.info) {
    const id = ownProps.info.question_id;
    var thisQ = questions.find(x => x.id === id);
  }
  const question = thisQ ? thisQ : null;
  return {
    question: question
  };
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "questions" }])
)(QandA);
