import { charge } from "../api/functions.js";
// import { applicationFormSelector } from "../selectors/base.selectors";

const ACTION_PREFIX = "@@checkout";
export const CARD_ERROR = `${ACTION_PREFIX}/CARD_ERROR`;
export const CHECKOUT_SUCCESSFUL = `${ACTION_PREFIX}/CHECKOUT_SUCCESSFUL`;

export const checkoutSuccessful = () => ({ type: CHECKOUT_SUCCESSFUL });

export const cardError = (error = "An error has occured with your card.") => ({
  type: CARD_ERROR,
  error
});

export const submitCharge = (token, stuffz) => (dispatch, getState) => {
  console.log("submitting charge...");
  console.log(stuffz);
  // const state = getState();
  //   const cart = cartItemsSelector(state);
  // const { email, firstname, lastname } = applicationFormSelector(state);
  let curCharge = {
    amount: stuffz.amount,
    currency: "usd",
    description: "Example charge",
    source: token,
    email: stuffz.email,
    name: stuffz.name, //`${firstname} ${lastname}`,
    order_id: "1234567890"
  };
  console.log(curCharge);
  return charge(curCharge)
    .then(() => {
      console.log("checkout successful");
      dispatch(checkoutSuccessful());
      //   dispatch(push("/checkout-success"));
    })
    .catch(err => {
      console.log("checkout error");
      dispatch(cardError());
      throw err;
    });
};
