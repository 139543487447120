import React from "react";
import style from "./style.module.scss";
// import classNames from "classnames";
import ImageSlider from "../../../artists/image-slider/image-slider.js";
import ArtistIntro from "../../../artists/artist-intro/artist-intro.js";
import QandA from "../../../artists/q-and-a/q-and-a.js";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

import {
  acceptApplicationForReview,
  deleteApplication,
  denyApplication,
  updateImages,
  removeAllImages
} from "../../../../store/actions/application-actions.js";

class ApplicationView extends React.Component {
  handleDelete = e => {
    // console.log("application-view: handleDelete(e)");
    if (this.areYouSure("delete application")) {
      Promise.all([
        this.props.removeAllImages(this.props.application, null)
      ]).then(() => {
        this.props.deleteApplication(this.props.application, this.props.appID);
        // then push to next application
      });
    }
  };

  handleDeny = e => {
    // console.log("application-view: handleDeny(e)");
    if (this.areYouSure("deny application")) {
      Promise.all([
        this.props.removeAllImages(this.props.application, null)
      ]).then(() => {
        this.props.denyApplication(this.props.application, this.props.appID);
        // then push to next application
      });
    }
  };

  handleAccept = e => {
    // console.log("application-view: handleAccept(e)");
    this.props.acceptApplicationForReview(
      this.props.application,
      this.props.appID
    );
  };

  areYouSure(reason) {
    return window.confirm("Are you sure you want to " + reason + "?");
  }

  render() {
    const { application } = this.props;
    const appID = this.props.appID;

    if (application) {
      // if (application.imageLoader.images[0].fileUrl === undefined) {
      //   this.props.updateImages(application.imageLoader.images);
      // }

      const questions = application.questions;
      const questionsGroup = [];
      // const inputs = application.inputs;
      // const website = inputs.website.answer;
      // const instagram = inputs.instagram.answer;

      for (var index in questions) {
        const curQ = questions[index];
        questionsGroup.push(<QandA info={curQ} key={curQ.id} secret={true} />);
      }

      return (
        <div className={style.application_view}>
          <div className={style.app_contents}>
            <ImageSlider artist={application} artistID={appID} />

            {/* Questions */}
            <div className={style.info}>
              <ArtistIntro artist={application} secret={true} />
              {questions ? (
                questionsGroup
              ) : (
                <div>
                  <h1>
                    This application does not contain answers to questions
                  </h1>
                </div>
              )}
            </div>

            {/* Footer Button Bar */}
            <div className={style.button_bar}>
              <button className="theme_button" onClick={this.handleDelete}>
                Delete
              </button>
              <button className="theme_button" onClick={this.handleDeny}>
                Deny
              </button>
              <button className="theme_button" onClick={this.handleAccept}>
                Accept
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p>Loading...</p>
        </div>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  const id = ownProps.match.params.id;
  const applications = state.firestore.data.applications;
  const application = applications ? applications[id] : null;
  return {
    application: application,
    appID: id
  };
}

function mapDispatchToProps(dispatch) {
  return {
    acceptApplicationForReview: (application, id) =>
      dispatch(acceptApplicationForReview(application, id)),
    deleteApplication: (application, id) =>
      dispatch(deleteApplication(application, id)),
    denyApplication: (application, id) =>
      dispatch(denyApplication(application, id)),
    updateImages: allImages => dispatch(updateImages(allImages)),
    removeAllImages: (application, folder) =>
      dispatch(removeAllImages(application, folder))
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "applications" }])
)(ApplicationView);
