// application
export const applicationSelector = state => state.application;
export const applicationFormSelector = state => state.application.form;
export const isFetchingFormSelector = state => state.application.isFetching;

// questions
export const allQuestionItemsSelector = state => state.questions.all;
export const lockedQuestionItemsSelector = state => state.questions.locked;
export const randomQuestionItemsSelector = state => state.questions.random;
export const endQuestionItemsSelector = state => state.questions.end;
export const isFetchingAllQuestionsSelector = state =>
  state.questions.isFetchingAll;
export const isFetchingLockedQuestionsSelector = state =>
  state.questions.isFetchingLocked;
export const isFetchingRandomQuestionsSelector = state =>
  state.questions.isFetchingRandom;
export const isFetchingEndQuestionsSelector = state =>
  state.questions.isFetchingEnd;
export const isSuccessSelector = state => state.questions.isSuccess;

//inputs
export const inputItemsSelector = state => state.inputs.all;
export const isFetchingInputsSelector = state => state.inputs.isFetchingAll;

//configuration
export const configurationItemsSelector = state => state.configurations.all;
export const isFetchingConfigurationsSelector = state =>
  state.inputs.isFetchingAll;

//submission codes
export const submissionCodesSelector = state =>
  state.firestore.ordered.submission_codes;

//artist
export const imagesSelector = state => state.artists.images;
