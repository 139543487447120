import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";

import { Link } from "react-router-dom";
// import { LogOut } from "react-feather";
import { Instagram, Mail, XSquare } from "react-feather";

import { connect } from "react-redux";
import { signOut } from "../../../store/actions/auth-actions.js";

class MenuDrawer extends React.Component {
  // handleSignOut = e => {
  //   this.props.signOut();
  //   this.props.handleMenuDrawerClose();
  // };
  render() {
    // const auth = this.props.auth;
    return (
      <div
        className={classNames(style.menu_drawer, {
          [style.hide]: !this.props.menuDrawerOpen
        })}
      >
        <div
          className={style.exit_button}
          onClick={this.props.handleMenuDrawerClose}
        >
          <XSquare />
        </div>
        <div className={style.top}>
          <Link to="/" onClick={this.props.handleMenuDrawerClose}>
            archive-ism
          </Link>
          {/* {auth.uid ? (
            <section>
              <a
                href="/admin/inbox"
                className={style.admin_label}
                onClick={this.props.handleMenuDrawerClose}
              >
                admin
              </a>
              <button
                className={style.icon_button}
                onClick={this.handleSignOut}
              >
                <LogOut />
              </button>
            </section>
          ) : null} */}
        </div>
        <div className={style.middle}>
          <Link to="/" onClick={this.props.handleMenuDrawerClose}>
            latest
          </Link>
          <Link to="/submit" onClick={this.props.handleMenuDrawerClose}>
            submit
          </Link>
        </div>
        <div className={style.bottom}>
          <a
            href="https://www.instagram.com/archive_ism/"
            className={style.social_logo}
            rel="noopener noreferrer"
            target="_blank"
            onClick={this.props.handleMenuDrawerClose}
          >
            <Instagram />
          </a>
          <a
            href="mailto:info@archive-ism.com"
            className={style.social_logo}
            rel="noopener noreferrer"
            target="_blank"
            onClick={this.props.handleMenuDrawerClose}
          >
            <Mail />
          </a>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.firebase.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: () => dispatch(signOut())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuDrawer);
