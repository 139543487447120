import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import { connect } from "react-redux";
import TextInput from "../../../application-form/text-input/text-input.js";
import { createCode } from "../../../../store/actions/submission-code-actions.js";

class CreateCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      max: "",
      duration: ""
    };
  }

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    console.log(this.state);
    if (this.state.code.length > 0) {
      this.props.createCode(this.state);
      this.setState({ code: "", max: "", duration: "" });
    } else {
    }
  };

  render() {
    console.log(this.state.code);
    const newQueInputs = {
      code: {
        key: "code",
        placeholder: "Type a custom code..."
      },
      max: {
        key: "max",
        placeholder: "Max Uses"
      },
      duration: {
        key: "duration",
        max: "3",
        placeholder: "Days Active"
      }
    };
    const newQueGroup = [];
    for (var curInput in newQueInputs) {
      let input = newQueInputs[curInput];
      let key = input.key;

      newQueGroup.push(
        <TextInput
          key={key}
          id={key}
          inputs={input}
          onChange={this.handleChange}
        />
      );
    }

    return (
      <form
        onSubmit={this.handleSubmit}
        className={classNames(style.container_s, style.createContainer)}
      >
        {newQueGroup[0]}
        <div className={style.options}>
          {newQueGroup[1]}
          {newQueGroup[2]}
          <button className={classNames("theme_button")}>Generate</button>
          <button className={classNames("theme_button")} type="submit">
            Create
          </button>
        </div>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createCode: codeObj => dispatch(createCode(codeObj))
  };
}

export default connect(
  null,
  mapDispatchToProps
)(CreateCode);
