import {
  IS_FETCHING_ALL,
  FETCHED_ALL
} from "../actions/input-field-actions.js";

const defaultState = {
  isFetchingAll: false,
  all: {}
};

export default function inputFieldReducer(state = defaultState, action) {
  switch (action.type) {
    case IS_FETCHING_ALL:
      return { ...state, isFetchingAll: true };
    case FETCHED_ALL:
      return { ...state, isFetchingAll: false, all: action.inputs };
    default:
      return state;
  }
}
