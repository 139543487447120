import React, { Fragment } from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import Footer from "../layout/footer/footer.js";
import DonateForm from "../checkout-form/donate-form.js";
import { injectStripe } from "react-stripe-elements";
import { connect } from "react-redux";

import PaymentWrapper from "../application-form/payment.wrapper";

class Donate extends React.Component {
  render() {
    return (
      <Fragment>
        <form
          onSubmit={this.handleSubmit}
          className={classNames(style.container_s, style.donate)}
        >
          <div>donate here</div>
          <DonateForm header="Donate" />
        </form>
        <Footer />
      </Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default PaymentWrapper(
  injectStripe(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Donate)
  )
);
